import React, { useState } from "react";

const CarPart = ({ partId, d, color, handleClick, transform }) => {
  const fillColor =
    (partId === 24 || partId === 25) ? (color === "none" ? "white" : color) : (color === "none" ? "#C0C0C0" : color);
      
  return (
    <path
      d={d} // SVG Path command
      fill={fillColor}
      stroke="gray"
      strokeWidth="2"
      // onClick={() => handleClick(partId)}
      transform={transform}
      style={{ cursor: "pointer" }}
    />
  );
};

export default CarPart;
