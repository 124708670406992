import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    MenuItem,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from "react-redux";
import jwtAxios, { setAuthToken } from '@crema/services/auth/jwt-auth';
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import SmartInspectLogo from '../assests/images/SmartInspectLogo.jpeg'

const SmartInspectForm = () => {
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [errors, setErrors] = useState({});
    // const [selectedDate, setSelectedDate] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobNumber: '',
        vehicleNumber: '',
        inspectionOption: '',
        insuranceCompany: '',
        claimAssistance: '',
        companyName: '',
        policyNumber: '',
        policyExpiryDate: '',
        damageBrief: '',
        thirdPartyDamageBrief: '',
        payment: '',
    });
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Auto-login logic
                const email = 'dev@gmail.com';
                const password = 'dev123';
                const loginRes = await jwtAxios.post('auth/webuser/login', { email, password });

                // console.log(loginRes.data.accessToken);
                localStorage.setItem('token', loginRes.data.accessToken);
                setAuthToken(loginRes.data.accessToken);

                // Fetching clients after login
                const clientResponse = await jwtAxios.get('auth/bank');

                const formatOptions = (data) => data.map(item => ({
                    label: item.name,
                    value: item.name
                }));

                setClients([...formatOptions(clientResponse.data)]);
            } catch (error) {
                console.error("Error during auto-login or fetching data:", error);
            }
        };

        fetchData();
    }, [dispatch]);

    //   console.log("clients", clients)

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(formData);

        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.email) newErrors.email = "Email is required.";
        // if (!formData.vehicleNumber) newErrors.vehicleNumber = "Vehicle number is required.";
        // if (!formData.inspectionOption) newErrors.inspectionOption = "Inspection option is required.";
        // if (!formData.insuranceCompany) newErrors.insuranceCompany = "Insurance company is required.";
        // if (!formData.claimAssistance) newErrors.claimAssistance = "Claim assistance choice is required.";
        // if (!formData.companyName) newErrors.companyName = "Company name is required.";
        // if (!formData.policyNumber) newErrors.policyNumber = "Policy number is required.";

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error(newErrors);
            return;
        }

        try {
            const response = await axios.post('https://smartinspectai.justthinktech.in/smartinspectionai/v1/smartinspect-sheetapi', formData);
            // console.log('Success:', response.data);
            toast.success('Form submitted successfully');

            // Email notification
            await axios.post('https://smartinspectai.justthinktech.in/smartinspectionai/v1/send-email', { formData });

            setFormData({
                name: '',
                email: '',
                mobNumber: '',
                vehicleNumber: '',
                inspectionOption: '',
                insuranceCompany: '',
                claimAssistance: '',
                companyName: '',
                policyNumber: '',
                policyExpiryDate: '',
                damageBrief: '',
                thirdPartyDamageBrief: '',
                payment: '',
            });

            setErrors({});

        } catch (error) {
            console.error('Error:', error);
            toast.error('Error !', error);
        }
    };

    return (
        <Box sx={{
            width: { xs: '100%', sm: '70%', md: '50%' },
            margin: '0 auto',
            padding: 5,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: 2,
        }}
        >
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate('/form')}
                sx={{ marginBottom: 2 }}
            >
                Back
            </Button>
            {/* <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
                <img 
                    src={SmartInspectLogo} 
                    alt="Smart Inspect Logo"
                />
            </Box> */}
            <form onSubmit={handleSubmit}>
                <Typography variant="h4" fontWeight={600} gutterBottom>
                    SmartInspectAI
                </Typography>
                <Typography variant='subtitle1'>
                    <span style={{ fontWeight: 'bold', color: "gray" }}>SmartInspectAI</span> is an innovative AI-based car damage detection app designed to streamline
                    pre-inspection processes and small claim assessments.
                </Typography>

                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    error={!!errors.name}
                    helperText={errors.name}
                />

                <TextField
                    label="Email for report"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />

                <TextField
                    label="Enter Mobile Number"
                    name="mobNumber"
                    type="mobNumber"
                    value={formData.mobNumber}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />

                <TextField
                    label="Vehicle Number/Registration Number"
                    name="vehicleNumber"
                    value={formData.vehicleNumber.toUpperCase()}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                // helperText="Please provide in capital letters"
                />

                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">Choose one option</FormLabel>
                    <RadioGroup
                        name="inspectionOption"
                        value={formData.inspectionOption}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value="selfInspection"
                            control={<Radio />}
                            label="Looking Self Inspection for better Insurance price"
                        />
                        <FormControlLabel
                            value="claimAssessment"
                            control={<Radio />}
                            label="Self Claim Assessment"
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField
                        select
                        label="Preferable Insurance company name for renewal"
                        name="insuranceCompany"
                        value={formData.insuranceCompany}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    >
                        {clients.map((bank) => (
                            <MenuItem key={bank.value} value={bank.value}>
                                {bank.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>

                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">Do you want us to help in lodging a claim with your Insurer?</FormLabel>
                    <RadioGroup
                        name="claimAssistance"
                        value={formData.claimAssistance}
                        onChange={handleChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                        />
                    </RadioGroup>
                </FormControl>

                <TextField
                    label="Insurance Company Name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    label="Policy Number"
                    name="policyNumber"
                    value={formData.policyNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />

                <FormControl fullWidth margin="normal">
                    <FormLabel sx={{ marginBottom: '5px' }}>Policy Valid Upto</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Select date"
                            value={formData.policyExpiryDate ? dayjs(formData.policyExpiryDate, 'DD/MM/YYYY') : null}  // Parse the stored date if exists
                            onChange={(newValue) => {
                                const formattedDate = dayjs(newValue).format('DD/MM/YYYY');  // Format the date as dd/mm/yyyy
                                setFormData({
                                    ...formData,
                                    policyExpiryDate: formattedDate  // Store formatted date
                                });
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!errors.policyExpiryDate}
                                    helperText={errors.policyExpiryDate}
                                />}
                        />
                    </LocalizationProvider>
                </FormControl>

                <TextField
                    fullWidth
                    margin="normal"
                    name='damageBrief'
                    label="A brief details about damage"
                    value={formData.damageBrief}
                    onChange={handleChange}
                    multiline
                    rows={3}
                />

                <TextField
                    fullWidth
                    margin="normal"
                    name='thirdPartyDamageBrief'
                    label="Is there any third party damage?"
                    value={formData.thirdPartyDamageBrief}
                    onChange={handleChange}
                    multiline
                    rows={2}
                />

                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">I am ok to pay</FormLabel>
                    <RadioGroup
                        name='payment'
                        value={formData.payment}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="0" control={<Radio />} label="Rs. 0/- Self Inspection for Insurance Renewal" />
                        <FormControlLabel value="99" control={<Radio />} label="Rs. 99/- Self Claim Assessment" />
                        <FormControlLabel value="199" control={<Radio />} label="Rs. 199/-* for Physical Inspection" />
                    </RadioGroup>
                </FormControl>

                <Typography variant="h4" fontWeight={600} gutterBottom>Disclaimer:</Typography>
                <Typography variant="body2" color="textSecondary">
                    By submitting your information, you agree that <b>SmartInspectAI</b> will contact you
                    at the provided phone number regarding your damage assessment and any related services.
                </Typography>

                {/* <Box textAlign="center" marginTop={2}> */}
                <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 5 }}>
                    Submit
                </Button>
                {/* </Box> */}
            </form>

            <ToastContainer /> {/* To show pop message */}
        </Box>
    );
};

export default SmartInspectForm;
