import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const InstaValuerForm = () => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
    });

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
    };
    return (
        <Box sx={{
            width: { xs: '100%', sm: '70%', md: '50%' },
            margin: '0 auto',
            padding: 5,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: 2,
        }}
        >
            <Button
                // variant="outlined" 
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate('/form')}
                sx={{ marginBottom: 2 }}
            >
                Back
            </Button>
            <form onSubmit={handleSubmit}>
                <Typography variant="h4" fontWeight={600} gutterBottom>
                    InstaValuer
                </Typography>

                <TextField
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />

                <TextField
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />

                <Typography variant="subtitle" fontWeight={600} gutterBottom>
                    Some features are still under development. Stay tuned for updates!
                </Typography>

                {/* <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
                    Submit
                </Button> */}
            </form>
        </Box>
    )
}

export default InstaValuerForm;