import React from 'react';
import { FaRegCalendarAlt, FaRegHospital } from 'react-icons/fa';
import { FiMap, FiUsers } from 'react-icons/fi';
import { HiOutlineAcademicCap, HiOutlineChartSquareBar } from 'react-icons/hi';
import {
  RiCustomerService2Line,
  RiDashboardLine,
  RiFileUploadLine,
  RiShieldUserLine,
  RiTodoLine,
} from 'react-icons/ri';
import { BiCarousel, BiCartAlt, BiErrorAlt, BiTask } from 'react-icons/bi';
import {
  BsBriefcase,
  BsCart4,
  BsChatDots,
  BsCurrencyBitcoin,
  BsQuestionDiamond,
} from 'react-icons/bs';
import { DiHtml5Multimedia } from 'react-icons/di';
import {
  MdHome,
  MdOutlineAnalytics,
  MdOutlineContactPhone,
  MdOutlineContactSupport,
  MdOutlineDns,
  MdOutlineHome,
  MdOutlineManageAccounts,
} from 'react-icons/md';
import { CgFeed } from 'react-icons/cg';
import { GrUserAdmin } from 'react-icons/gr';
import { AiOutlineEdit, AiOutlineUnorderedList } from 'react-icons/ai';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
import { TbFileInvoice } from 'react-icons/tb';

const getRole = () => {
  let role = localStorage.getItem("role");
  if (role === null) {
    role = sessionStorage.getItem("role");
  }
  return role ? role.split(',').join(", ") : [];
};

const getPosition = () => {
  let position = localStorage.getItem('position');
  if (position === null) {
    position = sessionStorage.getItem('position');
  }
  return position;
};

const role = getRole();
const position = getPosition();

// var role = sessionStorage.getItem("role")

// var position = sessionStorage.getItem('position');

console.log("Role", role, position)

const routesConfig =
  [
    {
      id: 'publicVerification',
      title: 'Verification',
      messageId: 'Verification',
      type: 'item',
      permittedRole: RoutePermittedRole.General, 
      icon: <FiMap />, 
      url: '/verification/:id',
    },
    ...(position === "SUPER ADMIN" ? [
      {
        id: 'app',
        title: 'Application',
        messageId: 'sidebar.application',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            messageId: 'dashboard',
            type: 'item',
            permittedRole: [RoutePermittedRole.User],
            icon: <MdOutlineHome />,
            url: '/dashboard',
          },
          {
            id: 'inspectionCases',
            title: 'Inspection Cases',
            messageId: 'Inspection Cases',
            type: 'collapse',
            icon: <MdOutlineDns/>,
            children: [
              {
                id: 'createCase',
                title: 'Create Case',
                messageId: 'Create Case',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/case',
              },
              {
                id: 'uploadCase',
                title: 'Upload Case',
                messageId: 'Upload Case',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/uploadProducts',
              },
              {
                id: 'unassignedCases',
                title: 'Unassigned Cases',
                messageId: 'Unassigned Cases',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/unAssignedCase',
              },
              {
                id: 'deleteCase',
                title: 'Delete Case',
                messageId: 'Delete Case',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/deleteCase',
              },
            ]
          },
          {
            id: 'reports',
            title: 'Reports',
            messageId: 'Reports',
            type: 'collapse',
            icon: <TbFileInvoice/>,
            children: [
              {
                id: 'pendingCases',
                title: 'Pending Cases',
                messageId: 'Pending Cases',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/pendingCase',
              },
              {
                id: 'caseReport',
                title: 'Case Report',
                messageId: 'Case Report',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/report/case',
              },
              {
                id: 'agentReport',
                title: 'Agent Report',
                messageId: 'Agent Report',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/agentsReport',
              },
              {
                id: 'agentRealTimeReport',
                title: 'Agent Realtime Report',
                messageId: 'Agent Realtime Report',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/agentRealtimeReport',
              },
              {
                id: 'misReport',
                title: 'MIS Report',
                messageId: 'MIS Report',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/misReport',
              },
              {
                id: 'salesReport',
                title: 'Sales Report',
                messageId: 'Sales Report',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/salesReport',
              },
            ]
          },
          {
            id: 'masters',
            title: 'Masters',
            messageId: 'Masters',
            type: 'collapse',
            icon: <MdOutlineDns/>,
            children: [
              {
                id: 'agentApprovals',
                title: 'Agent Approvals',
                messageId: 'Agent Approvals',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/agentApprovals',
              },
              {
                id: 'agentInAcive',
                title: 'Agent InActive',
                messageId: 'Agent InActive',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/agentsInActive',
              },
              {
                id: 'client',
                title: 'Client',
                messageId: 'Client',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/client',
              },
              {
                id: 'state',
                title: 'State',
                messageId: 'State',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/state',
              },
            ]
          },
          {
            id: 'Admin',
            title: 'Admin',
            messageId: 'Admin',
            type: 'collapse',
            icon: <MdOutlineDns/>,
            children: [
              {
                id: 'Create Users',
                title: 'Create Users',
                messageId: 'Create Users',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                url: '/smartinspectai/createUsers',
              },
            ]
          },
        ],
      },
    ] : 
      [
        {
          id: 'app',
          title: 'Application',
          messageId: 'sidebar.application',
          type: 'group',
          children: [
            ...(role.includes('dash1') ? [
              {
                id: 'dashboard',
                title: 'Dashboard',
                messageId: 'dashboard',
                type: 'item',
                permittedRole: [RoutePermittedRole.User],
                icon: <MdOutlineHome />,
                url: '/dashboard',
              }
            ] : []),
            ...(role.includes('case') ? [
              {
                id: 'inspectionCases',
                title: 'Inspection Cases',
                messageId: 'Inspection Cases',
                type: 'collapse',
                icon: <MdOutlineDns/>,
                children: [
                  ...(role.includes('case1') ? [
                    {
                      id: 'createCase',
                      title: 'Create Case',
                      messageId: 'Create Case',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/case',
                    }
                  ]:  []),
                  ...(role.includes('case2') ? [
                    {
                      id: 'uploadCase',
                      title: 'Upload Case',
                      messageId: 'Upload Case',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/uploadProducts',
                    }
                  ] : []),
                  ...(role.includes('case3') ? [
                    {
                      id: 'unassignedCases',
                      title: 'Unassigned Cases',
                      messageId: 'Unassigned Cases',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/unAssignedCase',
                    }
                  ] : []),
                  ...(role.includes('case4') ? [
                    {
                      id: 'deleteCase',
                      title: 'Delete Case',
                      messageId: 'Delete Case',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/deleteCase',
                    }
                  ] : []),
                ]
              },
            ] : []),
            ...(role.includes('report') ? [
              {
                id: 'reports',
                title: 'Reports',
                messageId: 'Reports',
                type: 'collapse',
                icon: <TbFileInvoice/>,
                children: [
                  ...(role.includes('report1') ? [
                    {
                      id: 'pendingCases',
                      title: 'Pending Cases',
                      messageId: 'Pending Cases',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/pendingCase',
                    }
                  ] : []),
                  ...(role.includes('report2') ? [
                    {
                      id: 'caseReport',
                      title: 'Case Report',
                      messageId: 'Case Report',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/report/case',
                    }
                  ] : []),
                  ...(role.includes('report3') ? [
                    {
                      id: 'agentReport',
                      title: 'Agent Report',
                      messageId: 'Agent Report',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/agentsReport',
                    }
                  ] : []),
                  ...(role.includes('report4') ? [
                    {
                      id: 'agentRealTimeReport',
                      title: 'Agent Realtime Report',
                      messageId: 'Agent Realtime Report',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/agentRealtimeReport',
                    }
                  ] : []),
                  ...(role.includes('report5') ? [
                    {
                      id: 'misReport',
                      title: 'MIS Report',
                      messageId: 'MIS Report',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/misReport',
                    }
                  ] : []),
                  ...(role.includes('report6') ? [
                    {
                      id: 'salesReport',
                      title: 'Sales Report',
                      messageId: 'Sales Report',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/salesReport',
                    }
                  ] : []),
                ]
              }
            ] : []),
            ...(role.includes('masters') ? [
              {
                id: 'masters',
                title: 'Masters',
                messageId: 'Masters',
                type: 'collapse',
                icon: <MdOutlineDns/>,
                children: [
                  ...(role.includes('masters1') ? [
                    {
                      id: 'agentApprovals',
                      title: 'Agent Approvals',
                      messageId: 'Agent Approvals',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/agentApprovals',
                    }
                  ] : []),
                  ...(role.includes('masters2') ? [
                    {
                      id: 'agentInAcive',
                      title: 'Agent InActive',
                      messageId: 'Agent InActive',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/agentsInActive',
                    }
                  ] : []),
                  ...(role.includes('masters3') ? [
                    {
                      id: 'client',
                      title: 'Client',
                      messageId: 'Client',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/client',
                    }
                  ] : []),
                  ...(role.includes('masters4') ? [
                    {
                      id: 'state',
                      title: 'State',
                      messageId: 'State',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/state',
                    }
                  ] : []),
                ]
              }
            ] : []),
            ...(role.includes('user') ? [
              {
                id: 'Admin',
                title: 'Admin',
                messageId: 'Admin',
                type: 'collapse',
                icon: <MdOutlineDns/>,
                children: [
                  ...(role.includes('user1') ? [
                    {
                      id: 'Create Users',
                      title: 'Create Users',
                      messageId: 'Create Users',
                      type: 'item',
                      permittedRole: [RoutePermittedRole.User],
                      url: '/smartinspectai/createUsers',
                    }
                  ] : []),
                ]
              }
            ] : []),
          ],
        },
    ])
  ] 
export default routesConfig;
