import React from 'react';
import { RoutePermittedRole } from '@crema/constants/AppEnums';
import ShippingOrders from 'modules/ecommerce/ShippingOrders';
import Category from 'modules/ecommerce/Admin/category';
import SubCategory from 'modules/ecommerce/Admin/subCategory';
import Brands from 'modules/ecommerce/Admin/brands';
import UploadProduct from 'modules/ecommerce/Admin/uploadProduct';
import Promocode from 'modules/ecommerce/Admin/promocode';
import BannerHome from 'modules/ecommerce/Admin/banner';
import ContactusImages from 'modules/ecommerce/Admin/ContactusImages';
import AboutusImages from 'modules/ecommerce/Admin/AboutusImages';


//orders all pages in sequence
import PendingOrders from 'modules/ecommerce/PendingOrders';
import CompletedOrders from 'modules/ecommerce/CompletedOrders';
import CreateUser from "../../../modules/ecommerce/Admin/createWebUser";
import CreateCase from 'modules/ecommerce/Admin/createCase';
import UnAssignedCases from 'modules/ecommerce/Admin/unAssignedCases';
import DeleteCase from 'modules/ecommerce/Admin/deleteCase';
import PendingCases from 'modules/ecommerce/Admin/pendingCases';
import Verification from 'modules/ecommerce/Verifications/SelfEvaluation';
import CaseReport from 'modules/ecommerce/Admin/caseReport';
import AgentsReport from 'modules/ecommerce/Admin/agentsReport';
import AgentRealtimeReport from 'modules/ecommerce/Admin/agentRealTimeReport';
import AgentApprovals from 'modules/ecommerce/Admin/agentApprovals';
import AgentsInActive from 'modules/ecommerce/Admin/agentsInActive';
import CreateUsers from 'modules/ecommerce/Admin/createUsers';
import SalesReport from 'modules/ecommerce/Admin/salesReport';
import Form1 from 'modules/ecommerce/Admin/manualForm1';
import Client from 'modules/ecommerce/Admin/client';
import State from 'modules/ecommerce/Admin/state';
import MisReport from 'modules/ecommerce/Admin/misReport';
const Orders = React.lazy(() => import('../../../modules/ecommerce/Orders'));
const Paymentscheck = React.lazy(() => import('../../../modules/ecommerce/PaymentsCheck'));
const PlaceOrder = React.lazy(() => import('../../../modules/ecommerce/PlaceOrder'));
const DimensionCalculation = React.lazy(() => import('../../../modules/ecommerce/DimensionCalculation'));
const Shipping = React.lazy(() => import('../../../modules/ecommerce/Shipping'));


const ConfiscatedOrder = React.lazy(() => import('../../../modules/ecommerce/ConfiscatedOrder'));
//orders all pages in sequence

const Products = React.lazy(
  () => import('../../../modules/ecommerce/Products'),
);
const ProductDetail = React.lazy(
  () => import('../../../modules/ecommerce/ProductDetail'),
);
const Customers = React.lazy(
  () => import('../../../modules/ecommerce/Customers'),
);
const CustomersAll = React.lazy(
    () => import('../../../modules/ecommerce/Customers/main_customers'),
);
const User = React.lazy(
  () => import('../../../modules/ecommerce/Customers/information'),
);
const EditUser = React.lazy(
    () => import('../../../modules/ecommerce/Customers/edit_customer'),
);
const Checkout = React.lazy(
  () => import('../../../modules/ecommerce/Checkout'),
);
const Carts = React.lazy(() => import('../../../modules/ecommerce/Carts'));
const Paymentcheck = React.lazy(() => import('../../../modules/ecommerce/PaymentCheck'));
// const paymentCheck = React.lazy(() => import('../../../modules/ecommerce/PaymentCheck'));
const OrdersDynamic = React.lazy(() => import('../../../modules/ecommerce/Orders/dynamic'));
const Confirmation = React.lazy(
  () => import('../../../modules/ecommerce/Confirmation'),
);
const Invoice1 = React.lazy(
  () => import('../../../modules/ecommerce/Invoice1'),
);
const Invoice2 = React.lazy(
  () => import('../../../modules/ecommerce/Invoice2'),
);

const ProductListing = React.lazy(
  () => import('../../../modules/ecommerce/Admin/Listing'),
);
const AddProduct = React.lazy(
  () => import('../../../modules/ecommerce/Admin/AddEditProduct'),
);
const EditProduct = React.lazy(
  () => import('../../../modules/ecommerce/Admin/EditProduct'),
);

export const ecommerceConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/invoice-1',
    element: <Invoice1 />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/invoice-2',
    element: <Invoice2 />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/products',
    element: <Products />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: ['/smartinspectai/product_detail/', '/smartinspectai/product_detail/:id'],
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/edituser',
    element: <EditUser />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/createWebUser',
    element: <CreateUser />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/user',
    element: <User />,
  },{
    permittedRole: RoutePermittedRole.User,
    path: '/customers/:phase',
    element: <Customers />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/customers/allEvery',
    element: <CustomersAll />,
  }
  ,{
    permittedRole: RoutePermittedRole.User,
    path: '/customers/amazon/:phase',
    element: <Customers />,
  },{
    permittedRole: RoutePermittedRole.User,
    path: '/customers/walmart/:phase',
    element: <Customers />,
  },{
    permittedRole: RoutePermittedRole.User,
    path: '/customers/others/:phase',
    element: <Customers />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/checkout',
    element: <Checkout />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/cart',
    element: <Carts />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/viewOrder',
    element: <Paymentcheck />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/inprogress',
    element: <Orders />,
  },{
    permittedRole: RoutePermittedRole.User,
    path: '/orders/label',
    element: <Orders />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/confiscation/pending',
    element: <Orders confiscation={false}/>,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/confiscation/applied',
    element: <Orders confiscation={true}/>,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/paymentCheck',
    element: <Paymentscheck />,
  },{
    permittedRole: RoutePermittedRole.User,
    path: '/orders/dimensionCalculation',
    element: <DimensionCalculation />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/placeOrder',
    element: <PlaceOrder />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/confiscatedOrder',
    element: <ConfiscatedOrder />,
  },
  // {
  //   permittedRole: RoutePermittedRole.User,
  //   path: '/orders/refunded',
  //   element: <ConfiscatedOrder />,
  // },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/waitingForShipment',
    element: <Shipping />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/shippingOrders',
    element: <ShippingOrders />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/completed',
    element: <CompletedOrders />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/confirmation',
    element: <Confirmation />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/reports/products',
    element: <ProductListing />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/orders/:state',
    element: <PendingOrders />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/add-products',
    element: <AddProduct />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/edit-products/:id',
    element: <EditProduct />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/category',
    element: <Category />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/case',
    element: <CreateCase />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/subCategory',
    element: <SubCategory />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/brands',
    element: <Brands />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/uploadProducts',
    element: <UploadProduct />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/promocode',
    element: <Promocode />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/bannerHome',
    element: <BannerHome />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/contactus',
    element: <ContactusImages />,
  },{
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/aboutus',
    element: <AboutusImages />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/report/orders',
    element: <OrdersDynamic />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/unAssignedCase',
    element: <UnAssignedCases />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/deleteCase',
    element: <DeleteCase />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/pendingCase',
    element: <PendingCases />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/report/case',
    element: <CaseReport />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/agentsReport',
    element: <AgentsReport />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/agentRealtimeReport',
    element: <AgentRealtimeReport />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/misReport',
    element: <MisReport />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/salesReport',
    element: <SalesReport />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/agentApprovals',
    element: <AgentApprovals />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/client',
    element: <Client />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/state',
    element: <State />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/agentsInActive',
    element: <AgentsInActive />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/createUsers',
    element: <CreateUsers />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/smartinspectai/manualForm/:_id',
    element: <Form1 />,
  },
];
