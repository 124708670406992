import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, TextField, FormControl, FormControlLabel, Checkbox, Button, InputLabel, Select, MenuItem, Box, Typography, Container, TableContainer, TableHead, TableRow, Table, TableBody, IconButton, TableCell } from "@mui/material";
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from "../../../constants/utilis";
// import CustomBreadcrumbs from "./Component/Breadcrumb";
import jwtAxios from "@crema/services/auth/jwt-auth";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from "react-toastify";


const CreateUsers = () => {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [stateName, setStateName] = useState([]);
    const [bankName, setBankName] = useState([]);
    const [states, setStates] = useState([]);
    const [banks, setBanks] = useState([]);
    const [userList, setUserList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [roles, setRoles] = useState({
      dash1: false,
      case1: false,
      case2: false,
      case3: false,
      case4: false,
      report1: false,
      report2: false,
      report3: false,
      report4: false,
      report5: false,
      report6: false,
      masters1: false,
      masters2: false,
      masters3: false,
      masters4: false,
      user1: false,
    });
    const [dataId, setDataId] = useState('');
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const stateResponse = await jwtAxios.get('auth/state'); // Get the state list
          const bankResponse = await jwtAxios.get('auth/bank'); // Get the bank list
  
          const formatOptions = (data) => data.map(item => ({
            label: item.name,
            value: item.name
          }));
  
          setStates([...formatOptions(stateResponse.data)]);
          setBanks([...formatOptions(bankResponse.data)]);
  
        } catch (error) {
          console.error(error);
          if (error.response?.status === 401 && error.response.data.message === "User Not authenticated") {
            sessionExpired();
          }
        }
      };
  
      fetchData();
    }, []);

    // Fetch webUser

    const fetchUser = () => {
      jwtAxios.get('auth/webUser') // Get the user list
        .then(response => {
            setUserList(response.data);
            // console.log(response.data, "Hello webUser")
        })
        .catch(error => {
            console.error(error);
            if (error.response && error.response.status === 401 && error.response.data.message === "User Not authenticated") {
                sessionExpired();
            }
        });
    }

    useEffect(() => {
      fetchUser();
    }, []);
  

//   console.log("webUser", userList);

  
    const handleEdit = (data) => {
      setEdit(true);
      setUsername(data.username);
      setName(data.name);
      setEmail(data.email);
      setPassword(data.password);
      setStateName(data.state.map(state => ({ label: state, value: state })));
      setBankName(data.bank.map(bank => ({ label: bank, value: bank })));
      setRoles({
        dash1: data.role.includes("dash1"),
        case1: data.role.includes("case1"),
        case2: data.role.includes("case2"),
        case3: data.role.includes("case3"),
        case4: data.role.includes("case4"),
        report1: data.role.includes("report1"),
        report2: data.role.includes("report2"),
        report3: data.role.includes("report3"),
        report4: data.role.includes("report4"),
        report5: data.role.includes("report5"),
        report6: data.role.includes("report6"),
        masters1: data.role.includes("masters1"),
        masters2: data.role.includes("masters2"),
        masters3: data.role.includes("masters3"),
        masters4: data.role.includes("masters4"),
        user1: data.role.includes("user1"),
      });
      setDataId(data._id);
      localStorage.setItem('formData', JSON.stringify(data));


      setEdit(true);
    };

    const handleDelete = async (key) => {
      if (window.confirm('Are you sure you want to delete the user?')) {
          // const config = jwtAxios.delete(`auth/webUser/${key._id}`)

          try {
              const response = await jwtAxios.delete(`auth/webUser/${key._id}`); // Delete the user by selected id
              if (response.status === 200) {
                  fetchUser(); // Update the list after deletion
                  setEdit(false);
                  resetForm();
                //   alert("Deleted Successfully");
                toast.success('Deleted Successfully');
              }
          } catch (error) {
              console.error(error);
          }
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!username || !name || !email || !password || stateName.length === 0 || bankName.length === 0) {
        //   alert("Please fill all required fields.");
        toast.error('All fields must be filled out.');
        return;
      }
  
      const role = Object.keys(roles).filter(roleKey => roles[roleKey]).join(',');
  
      const data = {
        username,
        name,
        email,
        password,
        role,
        position: "Admin",
        status: "Active",
        state: stateName.map(state => state.label),
        bank: bankName.map(bank => bank.label)
      };

    //   localStorage.setItem('formData', JSON.stringify(data));
        if(role === ''){
            toast.error('Please select at least one role.');
        }
  
      try {
        await jwtAxios.post('auth/webUser', data); // create a new user entery
        // Reset form and fetch updated list
        fetchUser();
        resetForm();
        // alert("Added Successfully");
        toast.success('Added Successfully');
      } catch (error) {
        console.error(error);
      }
  
    //   console.log("Data from admin", data);
    };
  
    const handleUpdate = async (e) => {
      e.preventDefault();
      if (!username || !name || !email || !password || stateName.length === 0 || bankName.length === 0) {
        // alert("Please fill all required fields.");
        toast.error('All fields must be filled out.');
        return;
      }
  
      const role = Object.keys(roles).filter(roleKey => roles[roleKey]).join(',');
  
      const data = {
        username,
        name,
        email,
        password,
        role,
        status: "Active",
        state: stateName.map(state => state.label),
        bank: bankName.map(bank => bank.label)
      };
  
      try {
        await jwtAxios.put(`auth/webUser/${dataId}`, data); // update the user by selected id
        // Reset form and fetch updated list
        fetchUser();
        resetForm();
        // alert("Updated Successfully");
        toast.success('Updated Successfully');
        setEdit(false);
      } catch (error) {
        console.error(error);
      }
    };

    // console.log("DataId", dataId);
  
    const handleRoleChange = (event) => {
      setRoles({ ...roles, [event.target.name]: event.target.checked });
    };
  
    const resetForm = () => {
      setUsername('');
      setName('');
      setEmail('');
      setPassword('');
      setStateName([]);
      setBankName([]);
      setRoles({
        dash1: false,
        case1: false,
        case2: false,
        case3: false,
        case4: false,
        report1: false,
        report2: false,
        report3: false,
        report4: false,
        report5: false,
        report6: false,
        masters1: false,
        masters2: false,
        masters3: false,
        masters4: false,
        user1: false,
      });
      setDataId('');
    };
  

    return (
        <Container>
            {/* <CustomBreadcrumbs title="Masters" breadcrumbItem="User Creation" /> */}
            <Typography variant="h2" fontWeight={800} marginBottom={4}>User Creation</Typography>
            <Card>
                <CardContent>
                    <form id="create-form" style={{ marginTop: '20px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Username"
                                    variant="outlined"
                                    fullWidth
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>State</InputLabel>
                                    <Select
                                        multiple
                                        value={stateName}
                                        onChange={(e) => setStateName(e.target.value)}
                                        renderValue={(selected) => selected.map(s => s.label).join(', ')}
                                        label="State"
                                    >
                                        {states.map((state) => (
                                            <MenuItem key={state.value} value={state}>
                                                {state.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Client</InputLabel>
                                    <Select
                                        multiple
                                        value={bankName}
                                        onChange={(e) => setBankName(e.target.value)}
                                        renderValue={(selected) => selected.map(b => b.label).join(', ')}
                                        label="Bank"
                                    >
                                        {banks.map((bank) => (
                                            <MenuItem key={bank.value} value={bank}>
                                                {bank.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid container spacing={2} style={{ margin: 16  }}>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "900", fontSize: '16px' }} >Dashboard</Typography>
                                <FormControlLabel
                                control={<Checkbox checked={roles.dash1} onChange={handleRoleChange} name="dash1" />}
                                label="Dashboard"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "900", fontSize: '16px' }} >Cases</Typography>
                                <FormControlLabel
                                control={<Checkbox checked={roles.case1} onChange={handleRoleChange} name="case1" />}
                                label="Create Cases"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.case2} onChange={handleRoleChange} name="case2" />}
                                label="Upload Case"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.case3} onChange={handleRoleChange} name="case3" />}
                                label="Unassigned Case"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.case4} onChange={handleRoleChange} name="case4" />}
                                label="Delete Case"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "900", fontSize: '16px' }} >Reports</Typography>
                                <FormControlLabel
                                control={<Checkbox checked={roles.report1} onChange={handleRoleChange} name="report1" />}
                                label="Pending Report"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.report2} onChange={handleRoleChange} name="report2" />}
                                label="Case Report"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.report3} onChange={handleRoleChange} name="report3" />}
                                label="Agent Report"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.report4} onChange={handleRoleChange} name="report4" />}
                                label="Agent Realtime Report"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.report5} onChange={handleRoleChange} name="report5" />}
                                label="MIS Report"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.report6} onChange={handleRoleChange} name="report6" />}
                                label="Sales Report"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "900", fontSize: '16px' }} >Masters</Typography>
                                <FormControlLabel
                                control={<Checkbox checked={roles.masters1} onChange={handleRoleChange} name="masters1" />}
                                label="Agent Approvals"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.masters2} onChange={handleRoleChange} name="masters2" />}
                                label="Agent InActive"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.masters3} onChange={handleRoleChange} name="masters3" />}
                                label="Client"
                                />
                                <FormControlLabel
                                control={<Checkbox checked={roles.masters4} onChange={handleRoleChange} name="masters4" />}
                                label="State"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Typography variant="subtitle1" sx={{ fontWeight: "900", fontSize: '16px' }}>Admin</Typography>
                                <FormControlLabel
                                control={<Checkbox checked={roles.user1} onChange={handleRoleChange} name="user1" />}
                                label="Create Users"
                                />
                                {/* <FormControlLabel
                                control={<Checkbox checked={roles.user2} onChange={handleRoleChange} name="user2" />}
                                label="Manage Users 2"
                                /> */}
                            </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between">
                                    {!edit ? <Button variant="contained" color="primary" onClick={handleSubmit}>
                                        Add
                                    </Button>
                                    :
                                    <Button variant="outlined" color="secondary" onClick={handleUpdate}>
                                        Update
                                    </Button>}
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            <Card sx={{ marginTop: '30px' }}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        User List
                    </Typography>
                    <TableContainer className="table-responsive">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sl.No</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Password</TableCell>
                                    <TableCell>State</TableCell>
                                    <TableCell>Bank</TableCell>
                                    <TableCell>Details</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map((rowData, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{rowData.name}</TableCell>
                                        <TableCell>{rowData.email}</TableCell>
                                        <TableCell>{rowData.username}</TableCell>
                                        <TableCell>{rowData.password}</TableCell>
                                        <TableCell>{rowData.state.join(', ')}</TableCell>
                                        <TableCell>{rowData.bank.join(', ')}</TableCell>
                                        <TableCell>
                                            {/* <Button variant="contained" color="primary" onClick={() => handleEdit(rowData)}>
                                                Edit
                                            </Button> */}
                                            <IconButton onClick={() => handleEdit(rowData)}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            {/* <Button variant="contained" color="secondary" onClick={() => handleDelete(rowData)}>
                                                Delete
                                            </Button> */}
                                            <IconButton color="secondary" onClick={() => handleDelete(rowData)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>

            <ToastContainer /> {/* To show a toast message */}

        </Container>
    );
};

export default CreateUsers;


