import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Input,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
// import SelectField from './Component/SelectFiled';
import jwtAxios from '@crema/services/auth/jwt-auth';
import uploadFile from 's3/s3.upload';
import SelectField from '../SelectFiled';
import useVahanVehicleDetails from '../../CustomHooks/useVahanVehicleDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomInput = styled('input')({
  width: '100%',
  paddingBottom: '2px',
  borderBottom: 'solid 1px gray'
});

const CommercialVehicle = ({ caseData, userDetails }) => {
  const location = useLocation();
  const { state } = location;
//   const caseData = state?.caseData;
//   const userDetails = state?.userDetails;
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  const [formData, setFormData] = useState({
    make: '',
    model: '',
    variant: '',
    color: '',
    year: '',
    vehicleRunningCondition: '',
    chasis: '',
    fuelUsed: '',
    engineNumber: '',
    odometerType: '',
    images: [],
    video: '',
    caseStatus: '',
    insurer: '',
    inspectorName: '',
    purpose: '',
    proposer: '',
    requestor: '',
    frontTyreRhs: '',
    frontTyreLhs: '',
    rearTyreRhs: '',
    rearTyreLhs: '',
    qcName: '',
    qcStatus: '',
    remarks: '',
    inspectionDateTime: '',
    cusSign: '',
    executiveName: '',

        // Commercial Vehicle-specific fields

        // Front
        commercialCowl: 'Intact',
        commercialBonnet: 'Intact',
        commercialBumper: 'Intact',
        commercialCabin: 'Intact',
        commercialHeadLightLt: 'Intact',
        commercialHeadLightRt: 'Intact',
        commercialIndicatorFrontRt: 'Intact',
        commercialIndicatorFrontLt: 'Intact',

        // Left Hand Side
        commercialLeftBody: 'Intact',
        commercialLeftDoor: 'Intact',

        // Rear
        commercialRearBody: 'Intact',
        commercialIndicatorRearLt: 'Intact',
        commercialIndicatorRearRt: 'Intact',

        // Right Hand Side
        commercialRightBody: 'Intact',
        commercialRightDoor: 'Intact',

        // Glasses
        commercialFrontWsGlass: 'Intact',
        commercialWindowGlassLt: 'Intact',
        commercialWindowGlassRt: 'Intact',
        commercialBackWSGlass: 'Intact',

        // Meter
        commercialOdometerType: 'Intact',
        commercialOdometer: 'Intact',
        commercialBattery: 'Intact',

        // Body / Exterior
        commercialBody: 'Intact',
        commercialSeat: 'Intact',
        commercialFuelTank: 'Intact',
        commercialLoader: 'Intact',
        commercialSilencer: 'Intact',

        // Tyres & Wheels
        commercialTyre: 'Intact',
        commercialWheelRims: 'Intact',
  });

  const clearFormData = () => {
    setFormData({
      make: '',
      model: '',
      variant: '',
      color: '',
      year: '',
      vehicleRunningCondition: '',
      chasis: '',
      fuelUsed: '',
      engineNumber: '',
      odometerType: '',
      images: [],
      video: '',
      caseStatus: 'Completed',
      insurer: '',
      inspectorName: '',
      purpose: '',
      proposer: '',
      requestor: '',
      qcName: '',
      qcStatus: '',
      remarks: '',
      inspectionDateTime: '',
      cusSign: '',
      executiveName: '',

        // Commercial Vehicle-specific fields

        // Front
        commercialCowl: '',
        commercialBonnet: '',
        commercialBumper: '',
        commercialCabin: '',
        commercialHeadLightLt: '',
        commercialHeadLightRt: '',
        commercialIndicatorFrontRt: '',
        commercialIndicatorFrontLt: '',

        // Left Hand Side
        commercialLeftBody: '',
        commercialLeftDoor: '',

        // Rear
        commercialRearBody: '',
        commercialIndicatorRearLt: '',
        commercialIndicatorRearRt: '',

        // Right Hand Side
        commercialRightBody: '',
        commercialRightDoor: '',

        // Glasses
        commercialFrontWsGlass: '',
        commercialWindowGlassLt: '',
        commercialWindowGlassRt: '',
        commercialBackWSGlass: '',

        // Meter
        commercialOdometerType: '',
        commercialOdometer: '',
        commercialBattery: '',

        // Body / Exterior
        commercialBody: '',
        commercialSeat: '',
        commercialFuelTank: '',
        commercialLoader: '',
        commercialSilencer: '',

        // Tyres & Wheels
        commercialTyre: '',
        commercialWheelRims: '',
    });
  };

  const signzyID = 'PAYTMSZY';

  const {vehicleData, error, loading} = useVahanVehicleDetails(userDetails.licensePlate, signzyID)

  // console.log("Vehicle Data",vehicleData);

  useEffect(() => {
    if (userDetails || vehicleData) {
      setFormData(prevState => ({
        ...prevState,
        make: vehicleData?.result?.mappings?.variantIds[0]?.make || userDetails.make || '',
        model: vehicleData?.result?.model || userDetails.model || '',
        variant: vehicleData?.result?.mappings?.variantIds[0]?.variant || userDetails.variant || '',
        color: vehicleData?.result?.vehicleColour || userDetails.color || '',
        year: vehicleData?.result?.vehicleManufacturingMonthYear || userDetails.year || '',
        chasis: vehicleData?.result?.chassis || userDetails.chasis || '',
        fuelUsed: vehicleData?.result?.type || userDetails.fuelUsed || '',
        engineNumber: vehicleData?.result?.engine || userDetails.engineNumber || '',
        insuranceCompany: vehicleData?.result?.vehicleInsuranceCompanyName || userDetails.insuranceCompany || '',
        insuranceUpto: vehicleData?.result?.vehicleInsuranceUpto || userDetails.insuranceUpto || '',
        odometerType: userDetails.odometerType || '',
        images: userDetails.images || '',
        video: userDetails.video || '',
        cusSign: userDetails.cusSign || '',
        executiveName: userDetails.executiveName || '',
        insurer: userDetails.insurer || '',
        inspectorName: userDetails.inspectorName || '',
        purpose: userDetails.purpose || '',
        proposer: vehicleData?.result?.owner || userDetails.proposer || '',
        requestor: userDetails.requestor || '',
        qcName: userDetails.qcName || '',
        remarks: userDetails.remarks || '',
        // threeWheelerFrontTyre: userDetails.threeWheelerFrontTyre || '',
        // threeWheelerRearTyreLt: userDetails.threeWheelerRearTyreLt || '',
        // threeWheelerRearTyreRt: userDetails.threeWheelerRearTyreRt || '',
      }));
    }
  }, [userDetails, vehicleData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const option = [
    { value: 'Intact', label: 'Intact' },
    { value: 'Scratched', label: 'Scratched' },
    { value: 'Damaged', label: 'Damaged' },
    { value: 'Broken', label: 'Broken' },
    { value: 'Dent', label: 'Dent' },
    { value: 'NA', label: 'NA' }
  ];

  const handleFileChange = async (event, type) => {
    const documentId = caseData._id;

    const files = Array.from(event.target.files);
    try {
      // Upload each file and get the URL
      const urls = await Promise.all(files.map(async (file) => {
        // const key = `uploads/${Date.now()}_${file.name}`;
        
        const key = `images/${documentId}/${Date.now()}_${file.name}`;
        const contentType = file.type;
        const fileUrl = await uploadFile(key, file, contentType);
        return fileUrl;
      }));

      if (type === 'video') {
        // If type is 'videos', we expect a single video URL
        setFormData(prevState => ({
            ...prevState,
            [type]: urls.length > 0 ? urls[0] : ''
        }));
      } else if (type === 'cusSign') {
        setFormData(prevState => ({
          ...prevState,
          [type] : urls.length > 0 ? urls[0] : ''
        }))
      }
      else {
        setFormData(prevState => ({
          ...prevState,
          [type]: [...prevState[type], ...urls]
        }));
      }
      
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleFileRemoveImages = (index, type) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index)
    }));
  };

  const handleFileRemoveVideo = (type) => {
    if(type === 'video') {
      URL.revokeObjectURL(formData.video);
      setFormData(prevState => ({
        ...prevState,
        video: ''
      }));
    }
  };

  const handleFileRemoveCusSign = (type) => {
      URL.revokeObjectURL(formData.cusSign);
      setFormData(prevState => ({
        ...prevState,
        [type]: ''
      }));
  };

  const handleFileDownload = (url) => {

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open in a new tab

    link.download = url.substring(url.lastIndexOf('/') + 1);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  

  const handleSubmit = () => {

    const currentDateTime = new Date().toISOString();

    setConfirmAction(() => async () => {
      const formDataWithStatus = {
        ...formData,
        caseStatus: 'Completed',
        inspectionDateTime: formData.inspectionDateTime
      };

      try {
        setTimeout(() => {
          jwtAxios.put(`/api/case/${caseData._id}`, formDataWithStatus)
          .then(response => {
            console.log('Update successful:', response);
            toast.success('Case Updated successfully');
            clearFormData();
          })
          .catch(error => {
            console.error('Error updating data:', error);
            toast.error('Error updating data:');
          });
          
        }, 3000);
      }
      catch (error) {
        console.log(error);
      }
      finally {
        setOpenDialog(false);
      }

    })
      setOpenDialog(true);
    
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirm = () => {
    confirmAction();
  }
  
  if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  // console.log("CaseData Commercial Vehicle Form", caseData);
  
    return (
      <Container maxWidth="md">
        <Box>
          { !vehicleData?.result?.regNo && (
            <Alert severity="warning">Vehicle Number is not registered with Vahan</Alert>
          )}
          <Box
            sx={{
              mt: 2
            }}
          >
  
            {/* Title */}
            <Box backgroundColor='#fefefe'
              sx={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px'
              }}
              padding={5}>
                <Typography>Name: {vehicleData?.result?.owner || userDetails.name}</Typography>
                <Typography>Email: {userDetails.email}</Typography>
                <Typography>Number: {vehicleData?.result?.mobileNumber || userDetails.number}</Typography>
                <Typography>Vehicle Name: {vehicleData?.result?.mappings?.variantIds[0]?.make || userDetails.vehicleName}</Typography>
                <Typography>Color: {vehicleData?.result?.vehicleColour || userDetails.color}</Typography>
                <Typography>License Plate: {vehicleData?.result?.regNo || userDetails.licensePlate}</Typography>
                <Typography>Address: {vehicleData?.result?.presentAddress || userDetails.address}</Typography>
              <Typography variant="h5" fontSize='24px' fontWeight='600' sx={{ mb: 3 }}>
                Commercial Vehicle Detail {caseData?.subCaseType && <span style={{ fontSize: '14px', color: 'gray' }}>({caseData?.subCaseType})</span>}
              </Typography>
  
              {/* Form Fields */}
              <TextField
                label="Make"
                name="make"
                value={formData.make}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Model"
                name="model"
                value={formData.model}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Variant"
                name="variant"
                value={formData.variant}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <Box display="flex" gap={3} justifyContent="space-between" mb={2}>
  
                <TextField
                  label="Color"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  fullWidth
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
  
                <TextField
                  label="Year"
                  name="year"
                  value={formData.year}
                  onChange={handleChange}
                  fullWidth
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
              </Box>
  
              <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '500' }}>
                  Vehicle Running Condition
                </Typography>
                <RadioGroup
                  name="vehicleRunningCondition"
                  value={formData.vehicleRunningCondition}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
  
              <TextField
                label="Chassis"
                name="chasis"
                value={formData.chasis}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Fuel Used"
                variant="outlined"
                name="fuelUsed"
                value={formData.fuelUsed}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Engine no"
                // variant="outlined"
                name="engineNumber"
                value={formData.engineNumber}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Insurance Upto"
                // variant="outlined"
                name="insuranceUpto"
                value={formData.insuranceUpto}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Insurance Company"
                // variant="outlined"
                name="insuranceCompany"
                value={formData.insuranceCompany}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Odometer"
                variant="outlined"
                name="odometerType"
                value={formData.odometerType}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Insurer/Broker"
                name="insurer"
                value={formData.insurer}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Inspector Name"
                name="inspectorName"
                value={formData.inspectorName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Purpose"
                name="purpose"
                value={formData.purpose}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Proposer"
                name="proposer"
                value={formData.proposer}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Requestor"
                name="requestor"
                value={formData.requestor}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="QC Name"
                name="qcName"
                value={formData.qcName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Executive Name"
                name="executiveName"
                value={formData.executiveName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Front
              </Typography>
  
              <SelectField
                label="Cowl"
                name="commercialCowl"
                value={formData.commercialCowl}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Bonnet"
                name="commercialBonnet"
                value={formData.commercialBonnet}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Bumper/Mudguard"
                name="commercialBumper"
                value={formData.commercialBumper}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Cabin (Steel/Wooden)"
                name="commercialCabin"
                value={formData.commercialCabin}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Head Light LT"
                name="commercialHeadLightLt"
                value={formData.commercialHeadLightLt}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Head Light RT"
                name="commercialHeadLightRt"
                value={formData.commercialHeadLightRt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Indicator Front RT"
                name="commercialIndicatorFrontRt"
                value={formData.commercialIndicatorFrontRt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Indicator Front LT"
                name="commercialIndicatorFrontLt"
                value={formData.commercialIndicatorFrontLt}
                onChange={handleChange}
                options={option}
              />
        
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Left Hand Side
              </Typography>
  
              <SelectField
                label="Left Body"
                name="commercialLeftBody"
                value={formData.commercialLeftBody}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Left Door"
                name="commercialLeftDoor"
                value={formData.commercialLeftDoor}
                onChange={handleChange}
                options={option}
              />
  
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Rear
              </Typography>
  
              <SelectField
                label="Rear Body"
                name="commercialRearBody"
                value={formData.commercialRearBody}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Indicator Rear LT"
                name="commercialIndicatorRearLt"
                value={formData.commercialIndicatorRearLt}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Indicator Rear RT"
                name="commercialIndicatorRearRt"
                value={formData.commercialIndicatorRearRt}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Right Hand Side
              </Typography>
  
              <SelectField
                label="Right Body"
                name="commercialRightBody"
                value={formData.commercialRightBody}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Right Door"
                name="commercialRightDoor"
                value={formData.commercialRightDoor}
                onChange={handleChange}
                options={option}
              />

              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Glasses
              </Typography>
  
              <SelectField
                label="Front W/S Glass"
                name="commercialFrontWsGlass"
                value={formData.commercialFrontWsGlass}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Window Glass LT"
                name="commercialWindowGlassLt"
                value={formData.commercialWindowGlassLt}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Window Glass RT"
                name="commercialWindowGlassRt"
                value={formData.commercialWindowGlassRt}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Back W/S Glass"
                name="commercialBackWSGlass"
                value={formData.commercialBackWSGlass}
                onChange={handleChange}
                options={option}
              />
  
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Meter
              </Typography>
  
              <SelectField
                label="Odometer Type"
                name="commercialOdometerType"
                value={formData.commercialOdometerType}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Odometer"
                name="commercialOdometer"
                value={formData.commercialOdometer}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Battery"
                name="commercialBattery"
                value={formData.commercialBattery}
                onChange={handleChange}
                options={option}
              />
  
              
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Tyre & Wheels
              </Typography>
  
  
              <SelectField
                label="Tyre"
                name="commercialTyre"
                value={formData.commercialTyre}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Wheel Rims"
                name="commercialWheelRims"
                value={formData.commercialWheelRims}
                onChange={handleChange}
                options={option}
              />
  
  
              {/* /////////////////////////////////////////////////////////////// */}
   
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                Body / Exterior
              </Typography>

              <SelectField
                label="Body (Steel/Wooden)"
                name="commercialBody"
                value={formData.commercialBody}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Seat"
                name="commercialSeat"
                value={formData.commercialSeat}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Fuel/Tank"
                name="commercialFuelTank"
                value={formData.commercialFuelTank}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Loader"
                name="commercialLoader"
                value={formData.commercialLoader}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="Silencer"
                name="commercialSilencer"
                value={formData.commercialSilencer}
                onChange={handleChange}
                options={option}
              />

              {/* //////////// */}
  
              <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '500' }}>
                  QC Status
                </Typography>
                <RadioGroup
                  name="qcStatus"
                  value={formData.qcStatus}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="Recommended" control={<Radio />} label="Recommended" />
                  <FormControlLabel value="Not Recommended" control={<Radio />} label="Not Recommended" />
                  <FormControlLabel value="Refer to Underwriter" control={<Radio />} label="Refer to Underwriter" />
                </RadioGroup>
              </FormControl>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Customer Sign</Typography>
              <CustomInput
                type="file"
                accept="image/png, image/gif, image/jpeg"
                // multiple
                onChange={(event) => handleFileChange(event, 'cusSign')}
                inputProps={{ accept: 'image/*' }}
                // fullWidth
                // margin="normal"
              />
  
              <Grid container spacing={2} mt={3}>
                {formData.cusSign.length > 0 ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <img src={formData.cusSign} alt="logo"/>
                    <Button variant="outlined" onClick={() => handleFileRemoveCusSign('cusSign')}>
                      Remove
                    </Button>
                  </Grid>
                ) : null
                  
                }
              </Grid>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Upload Images</Typography>
              <CustomInput
                type="file"
                accept="image/png, image/gif, image/jpeg"
                multiple
                onChange={(event) => handleFileChange(event, 'images')}
                inputProps={{ accept: 'image/*' }}
                // fullWidth
                // margin="normal"
              />
              <Grid container spacing={2} mt={3}>
                {formData.images.map((image, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    {/* <Typography variant="body2">{image}</Typography> */}
                    <img src={image} alt="logo"/>
                    <Grid display='flex' justifyContent='space-between'>
                      <Button variant="outlined" onClick={() => handleFileRemoveImages(index, 'images')}>
                        Remove
                      </Button>
  
                      <Button variant="outlined" onClick={() => handleFileDownload(image)}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Upload Videos</Typography>
              <Input
                type="file"
                accept='video/mp4, video/mpeg'
                multiple
                onChange={(event) => handleFileChange(event, 'video')}
                inputProps={{ accept: 'video/*' }}
                fullWidth
                margin="normal"
              />
  
              <Grid container spacing={2} m={3}>
                {formData.video.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <Typography variant="body2">{formData.video}</Typography> */}
                      <video controls style={{ width: '100%' }}>
                        <source src={formData.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Grid>
  
                    <Grid display='flex' justifyContent='space-between' gap={5}>
                      <Button variant="outlined" onClick={() => handleFileRemoveVideo('video')}>
                        Remove
                      </Button>
  
                      <Button variant="outlined" onClick={() => handleFileDownload(formData.video)}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                ) : <Typography variant="body2">Wait Till Upload ....</Typography>}
              </Grid>
  
              <Grid container spacing={2} m={3}>
                <TextField
                  label="Inspection Date & Time"
                  name="inspectionDateTime"
                  value={formData.inspectionDateTime}
                  onChange={handleChange}
                  type='datetime-local'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
              </Grid>
  
            </Box>
          </Box>
        </Box>
  
  
  
        {/* Footer */}
        <Box sx={{
          alignItems: 'center',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        >
          <Box display="flex" padding={2} backgroundColor='#fefefe' justifyContent="flex-end" alignItems="center"
            sx={{
              borderBottomRightRadius: '20px',
              borderBottomLeftRadius: '20px'
            }}
          >
            <Button
              sx={{
                backgroundColor: '#E5D3FC',
                color: '#6B08EC',
                fontWeight: '600',
                fontSize: '16px',
                borderRadius: '30px',
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
  
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
              {"Confirm Submission"}
          </DialogTitle>
          <DialogContent>
              <p>Are you sure you want to confirm?</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                  Confirm
              </Button>
          </DialogActions>
        </Dialog>
  
        <ToastContainer /> {/* To show pop up message */}
      </Container>
    );
};

export default CommercialVehicle;
