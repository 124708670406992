import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Slide,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import AppScrollbar from '@crema/components/AppScrollbar';
import AppCard from '@crema/components/AppCard';
import { openSnackbar } from "../../../toolkit/actions/snackbar";
import { useDispatch } from "react-redux";
import jwtAxios, { setAuthToken } from "@crema/services/auth/jwt-auth/index";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateCase2() {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [cases, setCases] = useState([]);
  const [states, setStates] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoadingTable] = useState(false);
  const [formData, setFormData] = useState({
    caseId: '',
    name: '',
    number: '',
    email: '',
    vehicleName: '',
    color: '',
    licensePlate: '',
    address: '',
    caseStatus: '',
    caseType: '',
    subCaseType: '',
    state: '',
    client: '',
    purpose: '',
    bodyType: '',
  });
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const dispatch = useDispatch();
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Auto-login logic
        const email = 'dev@gmail.com';
        const password = 'dev123';
        const loginRes = await jwtAxios.post('auth/webuser/login', { email, password });

        // console.log(loginRes.data.accessToken);
        localStorage.setItem('token', loginRes.data.accessToken);
        setAuthToken(loginRes.data.accessToken);

        // Fetching states and clients after login
        const stateResponse = await jwtAxios.get('auth/state');
        const clientResponse = await jwtAxios.get('auth/bank');
        
        const formatOptions = (data) => data.map(item => ({
          label: item.name,
          value: item.name
        }));
        
        setStates([...formatOptions(stateResponse.data)]);
        setClients([...formatOptions(clientResponse.data)]);
      } catch (error) {
        console.error("Error during auto-login or fetching data:", error);
        dispatch(openSnackbar('Failed to fetch data.', 'error'));
      }
    };

    fetchData();
  }, [dispatch]);

  const updateTable = () => {
    jwtAxios.get('api/case').then((response) => {
      setCases(response.data.data.caseList);
      setLoadingTable(false);
    });
  };

  useEffect(() => {
    updateTable();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'licensePlate') {
      setFormData({
          ...formData,
          [name]: value.toUpperCase()
      });
    } else {
      setFormData({
          ...formData,
          [name]: value
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.caseId) newErrors.caseId = 'Case Id is required';
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.number) newErrors.number = 'Number is required';
    if (!formData.licensePlate) newErrors.licensePlate = 'License Plate is required';
    if (!formData.caseType) newErrors.caseType = "Case Type is required";
    if (!formData.purpose) newErrors.purpose = "Type / Purpose is required";
    if (!formData.bodyType) newErrors.bodyType = "Body Type is required";
    if (!formData.client) newErrors.client = "Client is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      dispatch(openSnackbar('Please fill in all required fields', 'error'));
      toast.error('Please fill in all required fields');
      return;
    }

    setConfirmAction(() => async () => {
      const formDataWithStatus = {
        ...formData,
        caseStatus: 'Pending'
      };

      try {
        const response = await jwtAxios.post('api/case', formDataWithStatus);
        dispatch(openSnackbar('Case created successfully', 'success'));
        toast.success('Case created successfully');
        setFormData({
          caseId: '',
          name: '',
          number: '',
          email: '',
          vehicleName: '',
          color: '',
          licensePlate: '',
          address: '',
          caseStatus: 'Pending',
          caseType: '',
          subCaseType: '',
          state: '',
          client: '',
          purpose: '',
          bodyType: '',
        });
        updateTable();
      } catch (error) {
        console.error(error);
        dispatch(openSnackbar('Failed to create case', 'error'));
        toast.error('Failed to create case');
      } finally {
        setOpenDialog(false);
      }
    });

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    confirmAction();
  };
  const bodyTypeOption = [
    { value: 'SEDAN', label: 'Sedan' },
    { value: 'SUV', label: 'Suv' },
    { value: 'HATCHBACK', label: 'Hatch Back' }
  ];

  const caseTypeOption = [
    { value: 'Car', label: 'Car' },
    { value: 'Commercial Vehicle', label: 'Commercial Vehicle' },
    { value: 'Construction Equipment', label: 'Construction Equipment' },
    { value: 'Farm Equipment', label: 'Farm Equipment' },
    { value: 'Two Wheeler', label: 'Two Wheeler' },
    { value: 'Three Wheeler', label: 'Three Wheeler' }
  ];

  const subTypeOptions = [
    { value: 'Pvt Cars', label: 'Pvt Cars' },
    { value: 'Two Wheeler', label: 'Two Wheeler' },
    { value: 'Three Wheeler', label: 'Three Wheeler' },
    { value: 'LCV', label: 'LCV' },
    { value: 'Medium Commercial Vehicle', label: 'Medium Commercial Vehicle' },
    { value: 'Heavy Commercial Vehicle', label: 'Heavy Commercial Vehicle' },
    { value: 'Ambulance', label: 'Ambulance' },
    { value: 'Passenger Bus', label: 'Passenger Bus' },
    { value: 'Tractor', label: 'Tractor' },
    { value: 'School Bus', label: 'School Bus' },
    { value: 'Misc Vehicle Type', label: 'Misc Vehicle Type' },
  ];

  const purposeOption = [
    { value: 'Pre Inspection', label: 'Pre Inspection' },
    { value: 'Break-in', label: 'Break-in' },
    { value: 'Roll over', label: 'Roll over' },
    { value: 'Transfer', label: 'Transfer' },
    { value: 'Endorsement', label: 'Endorsement' },
    { value: 'Add On Coverage', label: 'Add On Coverage' },
    { value: 'Bi-Fuel Endorsement', label: 'Bi-Fuel Endorsement' },
    { value: 'Additional Accessories', label: 'Additional Accessories' },
    { value: 'Others', label: 'Others' },
    { value: 'Break-in and Nil Dep', label: 'Break-in and Nil Dep' },
    { value: 'Valuation', label: 'Valuation' },
    { value: 'Break-in and Add on', label: 'Break-in and Add on' },
    { value: 'Claim', label: 'Claim' },
  ];

  const filteredCases = cases.filter(caseItem =>
    caseItem.name.toLowerCase().includes(search.toLowerCase())
  );

  const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Box component='h2' sx={{ fontSize: 16, color: 'text.primary', fontWeight: 'bold', mb: { xs: 2, lg: 4 } }}>
        {'Create Case'}
      </Box>
      <Slide direction='right' in mountOnEnter unmountOnExit>
        <Grid item xs={12} lg={8}>
          <AppScrollbar style={{ height: 'auto', marginBottom: "6vh" }}>
            <AppCard>
              <form onSubmit={handleSubmit}>
                <TextField
                  className='mt-8 mb-16 mx-6 pb-16'
                  label='Case Id'
                  id='caseId'
                  name='caseId'
                  variant='outlined'
                  fullWidth
                  value={formData.caseId}
                  onChange={handleChange}
                  error={!!errors.caseId}
                  helperText={errors.caseId}
                />
                <br />
                <br />
                <TextField
                  className='mt-8 mb-16 mx-6 pb-16'
                  label='Name'
                  id='name'
                  name='name'
                  variant='outlined'
                  fullWidth
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <br />
                <br />
                <TextField
                  className='mt-8 mb-16 mx-6'
                  label='Number'
                  id='number'
                  name='number'
                  variant='outlined'
                  fullWidth
                  value={formData.number}
                  onChange={handleChange}
                  error={!!errors.number}
                  helperText={errors.number}
                />
                <br />
                <br />

                <TextField
                  className='mt-8 mb-16 mx-6'
                  label='Email'
                  id='email'
                  name='email'
                  variant='outlined'
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                />
                <br />
                <br />

                <TextField
                  className='mt-8 mb-16 mx-6'
                  label='Vehicle Name'
                  id='vehicleName'
                  name='vehicleName'
                  variant='outlined'
                  fullWidth
                  value={formData.vehicleName}
                  onChange={handleChange}
                />
                <br />
                <br />

                <TextField
                  className='mt-8 mb-16 mx-6'
                  label='Color'
                  id='color'
                  name='color'
                  variant='outlined'
                  fullWidth
                  value={formData.color}
                  onChange={handleChange}
                />
                <br />
                <br />

                <TextField
                  className='mt-8 mb-16 mx-6'
                  label='License Plate'
                  id='licensePlate'
                  name='licensePlate'
                  variant='outlined'
                  fullWidth
                  value={formData.licensePlate}
                  onChange={handleChange}
                  error={!!errors.licensePlate}
                  helperText={errors.licensePlate}
                />
                <br />
                <br />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Body Type</InputLabel>
                    <Select
                        name='bodyType'
                        value={formData.bodyType}
                        onChange={handleChange}
                        label="Case Type"
                        error={!!errors.bodyType}
                        helperText={errors.bodyType}
                    >
                        {bodyTypeOption.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.bodyType && (
                        <FormHelperText  error>{errors.bodyType}</FormHelperText>

                    )}
                  </FormControl>
                </Grid>
                <br/>

                <TextField
                  className='mt-8 mb-16 mx-6'
                  label='Address'
                  id='address'
                  name='address'
                  variant='outlined'
                  fullWidth
                  value={formData.address}
                  onChange={handleChange}
                />
                <br />
                <br />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Case Type</InputLabel>
                    <Select
                      name='caseType'
                      value={formData.caseType}
                      onChange={handleChange}
                      label="Case Type"
                      error={!!errors.caseType}
                    >
                      {caseTypeOption.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.caseType && (
                      <FormHelperText error>{errors.caseType}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <br />

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Sub Case Type</InputLabel>
                        <Select
                            name='subCaseType'
                            value={formData.subCaseType}
                            onChange={handleChange}
                            label="Sub Case Type"
                            // error={!!errors.subCaseType}
                            helperText={errors.subCaseType}
                        >
                            {subTypeOptions.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <br/>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>Type / Purpose</InputLabel>
                      <Select
                          name='purpose'
                          value={formData.purpose}
                          onChange={handleChange}
                          label="Type / Purpose"
                          error={!!errors.purpose}
                          helperText={errors.purpose}
                      >
                          {purposeOption.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                  {option.label}
                              </MenuItem>
                          ))}
                      </Select>
                      {errors.purpose && (
                          <FormHelperText  error>{errors.purpose}</FormHelperText>

                      )}
                  </FormControl>
                </Grid>
                <br/>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>State</InputLabel>
                    <Select
                      name='state'
                      value={formData.state}
                      onChange={handleChange}
                      label="State"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.value} value={state.value}>
                          {state.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <br />

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Client</InputLabel>
                    <Select
                      name='client'
                      value={formData.client}
                      onChange={handleChange}
                      label="Client"
                      error={!!errors.client}
                      helperText={errors.client}
                    >
                      {clients.map((bank) => (
                        <MenuItem key={bank.value} value={bank.value}>
                          {bank.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.client && (
                      <FormHelperText  error>{errors.client}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <br />

                <Button sx={{ display: 'block', minWidth: 100 }} color='primary' variant='contained' type='submit'>
                  Submit
                </Button>
              </form>
            </AppCard>
          </AppScrollbar>
        </Grid>
      </Slide>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Case Creation"}
        </DialogTitle>
        <DialogContent>
          <p>Are you sure you want to create this case?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer /> {/* To show pop message */}
    </>
  );
}

export default CreateCase2;
