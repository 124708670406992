import React, { useEffect, useMemo, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { useIntl } from 'react-intl';
import { MenuItem, Select, TextField, Typography } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import Box from '@mui/material/Box';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import { useAppDispatch, useAppSelector } from '../../../toolkit/hooks';
// import jwtAxios, { setAuthToken } from "../../../@crema/services/auth/jwt-auth";
// import { openSnackbar } from "../../../toolkit/actions/snackbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import ContactDetail from "../../apps/Contact/ContactDetail";
import { Button, Hidden, TableRow } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import axios from 'axios';
// import { downloadPdf } from '@crema/helpers/FileHelper';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';

const MisReport = () => {
  const phase = "all";
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [customerCount, setCustomerCount] = useState(60)
  const [page, setPage] = useState(0);
  const [isShowDetail, onShowDetail] = useState(false);
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const sellers = searchParams.get('seller');
  const report = !!searchParams.get('report');

  const [selectedContact, setSelectedContact] = useState(
    {}
  );
  const [search, setSearch] = useState("");
  const onViewContactDetail = (contact) => {
    setSelectedContact(contact);
    onShowDetail(true);
  };

  const navigate = useNavigate()

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    let config = {
      method: 'get',
      url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/case',
      headers: {
        'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    axios.request(config)
      .then((response) => {
        const completedCases = response.data.data.caseList.filter(caseItem => (caseItem.caseStatus !== 'Pending') && caseItem.name !== '');
        const sortedCases = completedCases.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setTableData(sortedCases)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onPageChange = (event, value) => {
    setPage(value);
  };

  const onRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // dispatch(getCustomers(search, page));
  }, [dispatch, search, page]);

  const onSearchCustomer = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    // if(search.length > 2 || search.length === 0) {
    //     updateTable()
    // }
  }, [search, phase, page, sellers])


  const filteredCases = tableData.filter(caseItem =>{
    const matchesSearch = caseItem.name.toLowerCase().includes(search.toLowerCase()) ||
      caseItem.licensePlate.toLowerCase().includes(search.toLowerCase()) ||
      caseItem.caseId.toLowerCase().includes(search.toLowerCase());

    const caseDate = dayjs(caseItem.createdAt);
    const matchesDateRange = (!startDate || caseDate.isAfter(dayjs(startDate))) &&
      (!endDate || caseDate.isBefore(dayjs(endDate).add(1, 'day')));

    return matchesSearch && matchesDateRange;
  });

  const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const downloadCSV = () => {
        const headers = [
        "SL No.", "Case Id", "Case Status", "Name", "Mobile", "Email",
        "Client Name", //"Client Branch", "Client Phone", 
        "Case Type", "Vehicle Type",
        "Vehicle Make", "Vehicle Model", "Year Of Manufacture", "Vehicle Variant",
        "Reg No.", "Chasis No.", "Engine No.", "Meter Reading", "Fuel Type",
        "Vehicle Color", "Insurance Expiry", "Vehicle Location",  // "Vehicle City",
        "Vehicle State", "Case Created Date and Time", "Field Service By/Agent",
        "Field Service Done Date and Time", // "Payment Mode",
        "Pdf Report Url", "Video Url",
        ];

        const escapeCsvField = (field) => {
          if (field === null || field === undefined) return '""';
          
          const stringField = String(field);
          if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
            return `"${stringField.replace(/"/g, '""')}"`;
          }
          return stringField;
        };

        const rows = filteredCases.map((data, index) => {
          const rowData = [
            index + 1, data.caseId, data.caseStatus, data.name, data.number, data.email,
            data.client,
            // data.clientBranch
            // data.clientPhone,
            data.caseType, data.caseType,
            data.make, data.model, data.year, data.variant,
            data.licensePlate, data.chasis, data.engineNumber, data.odometerType, data.fuelUsed,
            data.color, data.insuranceUpto, data.address, // data.address, // check city and location
            data.state, data.createdAt, data.agentname,
            data.inspectionDateTime, // we don't have payment mode
            data.pdfURL, data.video,
          ];

          return rowData.map(field => escapeCsvField(field));
        });

        const csvContent = [
        headers.join(","), // Header row
        ...rows.map(e => e.join(",")) // Data rows
        ].join("\n");

        // Add BOM for Excel to properly recognize UTF-8
        const BOM = '\uFEFF';
        const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "report.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url); // Clean up the URL object
        toast.success('Report  downloaded successfully');
    };

  return (
    <>
      <AppsContainer
        title={"MIS Report"}
        fullView
      >
        <AppsHeader>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
            }}
          >
            <AppSearchBar
              iconPosition='right'
              overlap={false}
              onChange={onSearchCustomer}
              placeholder={messages['common.searchHere']}
            />

            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Typography variant='subtitle' color='Highlight'>Start Date</Typography>
              <TextField
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                sx={{ m: 2 }}
              />
              <Typography variant='subtitle' color='Highlight'>End Date</Typography>
              <TextField
                type="date"
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                sx={{ m: 2 }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 'auto',
              }}
            >
              <Button onClick={downloadCSV}>Download CSV</Button>
              <Select
                labelId="demo-select-small-label"
                value={rowsPerPage}
                onChange={onRowsPerPageChange}
                variant="outlined"
                size='small'
                sx={{ margin: 1 }}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <Hidden smDown>
                <AppsPagination
                  rowsPerPage={rowsPerPage}
                  count={filteredCases.length}
                  page={page}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </Box>
          </Box>
        </AppsHeader>

        <AppsContent
          sx={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          <Table stickyHeader className='table'>
            <TableHead>
              <TableCell>SL No.</TableCell>
              <TableCell>Case Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Vehicle Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>License Plate</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Status</TableCell>
            </TableHead>
            <TableBody>
              {paginatedCases.map((data, index) => (
                <TableRow>
                  {/* {data.name !== "" && data.caseStatus === 'Completed' ? <> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{data.caseId}</TableCell>
                    <TableCell>{data.name}</TableCell>
                    <TableCell>{data.number}</TableCell>
                    <TableCell>{data.email}</TableCell>
                    <TableCell align='left'>
                        {dayjs(data.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{data.vehicleName}</TableCell>
                    <TableCell>{data.color}</TableCell>
                    <TableCell>{data.licensePlate}</TableCell>
                    <TableCell>{data.address}</TableCell>
                    <TableCell>{data.caseStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </AppsContent>

        <Hidden smUp>
          <AppsPagination
            rowsPerPage={rowsPerPage}
            count={filteredCases.length}
            page={page}
            onPageChange={onPageChange}
          />
        </Hidden>
      </AppsContainer>
      <AppInfoView />

      <ToastContainer /> {/* To show a toast message */}
    </>
  );
};

export default MisReport;
