import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuthUserType } from '@crema/types/models/AuthUser';
import jwtAxios, { setAuthToken } from './index';
import { useInfoViewActionsContext } from '@crema/context/AppContextProvider/InfoViewContextProvider';
import { useDispatch } from "react-redux";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from "../../../constants/ActionTypes";
import { useNavigate } from 'react-router-dom';

interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});

const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => { console.log("who are we??") },
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const [authData, setAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });
  const dispatch = useDispatch();

  const fetchStart = () => {
    dispatch({ type: FETCH_START });
  };
  const fetchError = (message: string) => {
    dispatch({ type: FETCH_ERROR, payload: message });
  };
  const fetchSuccess = () => {
    dispatch({ type: FETCH_SUCCESS });
  };

  const infoViewActionsContext = useInfoViewActionsContext();

  // Utility function to fetch user details from localStorage
  const getUserFromLocalStorage = () => {
    const userData = localStorage.getItem('userDetails');
    if (userData) {
      const user = JSON.parse(userData);
      if (user.uid && user.email && user.emailVerified !== false) {
        return {
          id: 1,
          uid: user.uid,
          displayName: user.displayName ? user.displayName : 'Admin',
          email: user.email,
          photoURL: user.photoURL ? user.photoURL : '/assets/images/avatar/A11.jpg',
          role: user.role || 'User',
        };
      }
    }
    return null;
  };

  useEffect(() => {
    // Start fetching
    fetchStart();

    // Fetch user from localStorage
    const user = getUserFromLocalStorage();
    if (user) {
      // Set auth token from localStorage if exists
      const token = localStorage.getItem('token');
      if (token) {
        setAuthToken(token);
      }

      setAuthData({
        user,
        isLoading: false,
        isAuthenticated: true,
      });
    } else {
      setAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }

    fetchSuccess();
  }, []);

  const signInUser = async ({ email, password }: SignInProps) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('auth/webuser/login', { email, password });
      console.log(data);
      localStorage.setItem('token', data.accessToken);
      setAuthToken(data.accessToken);

      // Save user details to localStorage
      localStorage.setItem('userDetails', JSON.stringify(data));

      sessionStorage.setItem('email', data.email);
      sessionStorage.setItem('name', data.name);
      sessionStorage.setItem('position', data.position);
      sessionStorage.setItem('role', data.role);

      setAuthData({
        user: data,
        isAuthenticated: true,
        isLoading: false,
      });
      infoViewActionsContext.fetchSuccess();
    } catch (error) {
      setAuthData({
        ...authData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError('Something went wrong');
    }
  };

  const signUpUser = async ({ name, email, password }: SignUpProps) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('users', { name, email, password });
      localStorage.setItem('token', data.token);
      setAuthToken(data.token);

      // Save user details to localStorage
      localStorage.setItem('userDetails', JSON.stringify(data));

      const res = await jwtAxios.get('/auth');
      setAuthData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
      infoViewActionsContext.fetchSuccess();
    } catch (error) {
      setAuthData({
        ...authData,
        isAuthenticated: false,
        isLoading: false,
      });
      infoViewActionsContext.fetchError('Something went wrong');
    }
  };

  const navigate = useNavigate();

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    setAuthToken();
    setAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });

    // Navigate to the login page
    navigate('/signin');
  };

  return (
    <JWTAuthContext.Provider value={{ ...authData }}>
      <JWTAuthActionsContext.Provider value={{ signUpUser, signInUser, logout }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};

export default JWTAuthAuthProvider;
