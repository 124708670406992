import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Input,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import jwtAxios from '@crema/services/auth/jwt-auth';
import uploadFile from 's3/s3.upload';
import SelectField from '../SelectFiled';
import useVahanVehicleDetails from '../../CustomHooks/useVahanVehicleDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomInput = styled('input')({
  width: '100%',
  paddingBottom: '2px',
  borderBottom: 'solid 1px gray'
});

const Car = () => {
  const location = useLocation();
  const { state } = location;
  const caseData = state?.caseData;
  const userDetails = state?.userDetails;
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(() => () => {});

  const [formData, setFormData] = useState({
    caseId: '',
    make: '',
    model: '',
    variant: '',
    color: '',
    year: '',
    vehicleRunningCondition: 'yes',
    chasis: '',
    fuelUsed: '',
    engineNumber: '',
    odometerType: '',
    frontPanel: 'Intact',
    frontBumper: caseData?.partsResponseAi?.frontBumper || 'Intact',
    headLightLeft: caseData?.partsResponseAi?.headLightLeft || 'Intact',
    headLightRight: caseData?.partsResponseAi?.headLightRight || 'Intact',
    frontIndicatorLeft: 'Intact',
    frontIndicatorRight: 'Intact',
    fogLampLeft: 'Intact',
    fogLampRight: 'Intact',
    grill: caseData?.partsResponseAi?.grill || 'Intact',
    frontWindowGlass: caseData?.partsResponseAi?.frontWindowGlass || 'Intact',
    leftFrontDoorGlass: caseData?.partsResponseAi?.leftFrontDoorGlass || 'Intact',
    leftRearDoorGlass: caseData?.partsResponseAi?.leftRearDoorGlass || 'Intact',
    rightRearDoorGlass: caseData?.partsResponseAi?.rightRearDoorGlass || 'Intact',
    rightFrontDoorGlass: caseData?.partsResponseAi?.rightFrontDoorGlass || 'Intact',
    rearWindowGlass: caseData?.partsResponseAi?.rearWindowGlass || 'Intact',
    insideRearViewMirror: 'Intact',
    rightOutsideRearViewMirror: caseData?.partsResponseAi?.rightOutsideRearViewMirror || 'Intact',
    sunroof: 'Intact',
    leftOutsideRearViewMirror: caseData?.partsResponseAi?.leftOutsideRearViewMirror || 'Intact',
    rightTrafficator: 'Intact',
    leftTrafficator: 'Intact',
    rightQRTPanel: caseData?.partsResponseAi?.rightQRTPanel || 'Intact',
    rightRearDoor: caseData?.partsResponseAi?.rightRearDoor || 'Intact',
    rightFrontDoor: caseData?.partsResponseAi?.rightFrontDoor || 'Intact',
    rightPillarFront: 'Intact',
    rightPillarCenter: 'Intact',
    rightPillarRear: 'Intact',
    rightRunningBoard: caseData?.partsResponseAi?.rightRunningBoard || 'Intact',
    rightFrontFender: caseData?.partsResponseAi?.rightFrontFender || 'Intact',
    rightRearFender: 'Intact',
    leftTailRight: caseData?.partsResponseAi?.leftTailRight || 'Intact',
    rightTailRight: caseData?.partsResponseAi?.rightTailRight || 'Intact',
    leftRearIndicator: 'Intact',
    rightRearIndicator: 'Intact',
    rearBumper: caseData?.partsResponseAi?.rearBumper || 'Intact',
    trunkLid: 'Intact',
    silencer: 'Intact',
    leftApron: 'Intact',
    rightApron: 'Intact',
    engineShield: 'Intact',
    battery: 'Intact',
    wheelType: 'Intact',
    leftFrontTyre: caseData?.partsResponseAi?.leftFrontTyre || 'Intact',
    leftRearTyre: caseData?.partsResponseAi?.leftRearTyre || 'Intact',
    rightFrontTyre: caseData?.partsResponseAi?.rightFrontTyre || 'Intact',
    rightRearTyre: caseData?.partsResponseAi?.rightRearTyre || 'Intact',
    spearWheels: 'Intact',
    wheelRims: 'Intact',
    dashboard: 'Intact',
    instrumentCluster: 'Intact',
    stereo: 'Intact',
    cdChanger: 'Intact',
    centerLockingSystem: 'Intact',
    airConditioner: 'Intact',
    seatCover: 'Intact',
    gearLock: 'Intact',
    airBagCondition: 'Intact',
    other: 'Intact',
    roof: 'Intact',
    images: [],
    video: '',
    caseStatus: '',
    insurer: '',
    inspectorName: '',
    purpose: '',
    proposer: '',
    requestor: '',
    frontRimLeft: caseData?.partsResponseAi?.frontRimLeft || 'Intact',
    rearRimLeft: caseData?.partsResponseAi?.rearRimLeft || 'Intact',
    bonnetFront: caseData?.partsResponseAi?.bonnetFront || 'Intact',
    frontRimRight: caseData?.partsResponseAi?.frontRimRight || 'Intact',
    rearRimRight: caseData?.partsResponseAi?.rearRimRight || 'Intact',
    dickyRear: caseData?.partsResponseAi?.dickyRear || 'Intact',
    spareTyreRear: 'Intact',
    floorTop: 'Intact',
    frontTyreRhs: '',
    frontTyreLhs: '',
    rearTyreRhs: '',
    rearTyreLhs: '',
    qcName: '',
    qcStatus: '',
    remarks: '',
    underCarriage: 'Intact',
    inspectionDateTime: '',
    leftFrontFender: caseData?.partsResponseAi?.leftFrontFender || 'Intact',
    leftRearFender: 'Intact',
    leftFrontDoor: caseData?.partsResponseAi?.leftFrontDoor || 'Intact',
    leftPillarFront: 'Intact',
    leftPillarCenter: 'Intact',
    leftPillarRear: 'Intact',
    leftRearDoor: caseData?.partsResponseAi?.leftRearDoor || 'Intact',
    leftRunningBoard: caseData?.partsResponseAi?.leftRunningBoard || 'Intact',
    leftQRTPanel: caseData?.partsResponseAi?.leftQRTPanel || 'Intact',
    cusSign: '',
    executiveName: '',
    vehicleRegistration: '',
  });

  const clearFormData = () => {
    setFormData({
      caseId: '',
      make: '',
      model: '',
      variant: '',
      color: '',
      year: '',
      vehicleRunningCondition: '',
      chasis: '',
      fuelUsed: '',
      engineNumber: '',
      odometerType: '',
      frontPanel: '',
      frontBumper: '',
      headLightLeft: '',
      headLightRight: '',
      frontIndicatorLeft: '',
      frontIndicatorRight: '',
      fogLampLeft: '',
      fogLampRight: '',
      grill: '',
      frontWindowGlass: '',
      leftFrontDoorGlass: '',
      leftRearDoorGlass: '',
      rightRearDoorGlass: '',
      rightFrontDoorGlass: '',
      rearWindowGlass: '',
      insideRearViewMirror: '',
      rightOutsideRearViewMirror: '',
      sunroof: '',
      leftOutsideRearViewMirror: '',
      rightTrafficator: '',
      leftTrafficator: '',
      rightQRTPanel: '',
      rightRearDoor: '',
      rightFrontDoor: '',
      rightPillarFront: '',
      rightPillarCenter: '',
      rightPillarRear: '',
      rightRunningBoard: '',
      rightFrontFender: '',
      rightRearFender: '',
      leftTailRight: '',
      rightTailRight: '',
      leftRearIndicator: '',
      rightRearIndicator: '',
      rearBumper: '',
      trunkLid: '',
      silencer: '',
      leftApron: '',
      rightApron: '',
      engineShield: '',
      battery: '',
      wheelType: '',
      leftFrontTyre: '',
      leftRearTyre: '',
      rightFrontTyre: '',
      rightRearTyre: '',
      spearWheels: '',
      wheelRims: '',
      dashboard: '',
      instrumentCluster: '',
      stereo: '',
      cdChanger: '',
      centerLockingSystem: '',
      airConditioner: '',
      seatCover: '',
      gearLock: '',
      airBagCondition: '',
      other: '',
      roof: '',
      images: [],
      video: '',
      caseStatus: 'Completed',
      insurer: '',
      inspectorName: '',
      purpose: '',
      proposer: '',
      requestor: '',
      frontRimLeft:'',
      rearRimLeft:'',
      bonnetFront:'',
      frontRimRight:'',
      rearRimRight: '',
      dickyRear: '',
      spareTyreRear: '',
      floorTop: '',
      frontTyreRhs: '',
      frontTyreLhs: '',
      rearTyreRhs: '',
      rearTyreLhs: '',
      qcName: '',
      qcStatus: '',
      remarks: '',
      underCarriage: '',
      inspectionDateTime: '',
      leftFrontFender: '',
      leftRearFender: '',
      leftFrontDoor: '',
      leftPillarFront: '',
      leftPillarCenter: '',
      leftPillarRear: '',
      leftRearDoor: '',
      leftRunningBoard: '',
      leftQRTPanel: '',
      cusSign: '',
      executiveName: '',
      vehicleRegistration: '',
    });
  };

  const signzyID = 'PAYTMSZY';

  const {vehicleData, error, loading} = useVahanVehicleDetails(userDetails.licensePlate, signzyID)

  // console.log("Vehicle Data",vehicleData);

  useEffect(() => {
    if (userDetails || vehicleData || caseData) {
      setFormData(prevState => ({
        ...prevState,
        caseId: caseData.caseId || '',
        make: vehicleData?.result?.mappings?.variantIds[0]?.make || userDetails.make || '',
        model: vehicleData?.result?.model || userDetails.model || '',
        variant: vehicleData?.result?.mappings?.variantIds[0]?.variant || userDetails.variant || '',
        color: vehicleData?.result?.vehicleColour || userDetails.color || '',
        year: vehicleData?.result?.vehicleManufacturingMonthYear || userDetails.year || '',
        chasis: vehicleData?.result?.chassis || userDetails.chasis || '',
        fuelUsed: vehicleData?.result?.type || userDetails.fuelUsed || '',
        engineNumber: vehicleData?.result?.engine || userDetails.engineNumber || '',
        insuranceCompany: vehicleData?.result?.vehicleInsuranceCompanyName || userDetails.insuranceCompany || '',
        insuranceUpto: vehicleData?.result?.vehicleInsuranceUpto || userDetails.insuranceUpto || '',
        odometerType: userDetails.odometerType || '',
        images: userDetails.images || '',
        video: userDetails.video || '',
        cusSign: userDetails.cusSign || '',
        executiveName: userDetails.executiveName || '',
        insurer: caseData?.client || userDetails.insurer || '',
        inspectorName: userDetails.inspectorName || '',
        purpose: userDetails.purpose || '',
        proposer: vehicleData?.result?.owner || userDetails.proposer || '',
        requestor: caseData?.client || userDetails.client || '',
        qcName: userDetails.qcName || '',
        remarks: userDetails.remarks || '',
        frontTyreRhs: userDetails.frontTyreRhs || '',
        frontTyreLhs: userDetails.frontTyreLhs || '',
        rearTyreRhs: userDetails.rearTyreRhs || '',
        rearTyreLhs: userDetails.rearTyreLhs || '',
        vehicleRegistration: vehicleData?.result?.regDate || userDetails.vehicleRegistration || '',
        // bodyType: vehicleData?.result?.bodyType || userDetails.bodyType || ''
      }));
    }
  }, [userDetails, vehicleData]);

  // console.log("Images", formData.images)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const option = [
    { value: 'Intact', label: 'Intact' },
    { value: 'Scratched', label: 'Scratched' },
    { value: 'Damaged', label: 'Damaged' },
    { value: 'Broken', label: 'Broken' },
    { value: 'Dent', label: 'Dent' },
    { value: 'NA', label: 'NA' }
  ];

  const handleFileChange = async (event, type) => {
    const documentId = caseData._id;

    const files = Array.from(event.target.files);
    try {
      // Upload each file and get the URL
      const urls = await Promise.all(files.map(async (file) => {
        // const key = `uploads/${Date.now()}_${file.name}`;
        
        const key = `images/${documentId}/${Date.now()}_${file.name}`;
        const contentType = file.type;
        const fileUrl = await uploadFile(key, file, contentType);
        return fileUrl;
      }));

      if (type === 'video') {
        // If type is 'videos', we expect a single video URL
        setFormData(prevState => ({
            ...prevState,
            [type]: urls.length > 0 ? urls[0] : ''
        }));
      } else if (type === 'cusSign') {
        setFormData(prevState => ({
          ...prevState,
          [type] : urls.length > 0 ? urls[0] : ''
        }))
      }
      else {
        setFormData(prevState => ({
          ...prevState,
          [type]: [...prevState[type], ...urls]
        }));
      }
      
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleFileRemoveImages = (index, type) => {
    setFormData(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index)
    }));
  };

  const handleFileRemoveVideo = (type) => {
    if(type === 'video') {
      URL.revokeObjectURL(formData.video);
      setFormData(prevState => ({
        ...prevState,
        video: ''
      }));
    }
  };

  const handleFileRemoveCusSign = (type) => {
      URL.revokeObjectURL(formData.cusSign);
      setFormData(prevState => ({
        ...prevState,
        [type]: ''
      }));
  };

  const handleFileDownload = (url) => {
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = url.substring(url.lastIndexOf('/') + 1); // Extract file name from URL
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'; // Open in a new tab

    link.download = url.substring(url.lastIndexOf('/') + 1);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
  


  // const handleSave = () => {

  //   console.log('Saving form data:', formData);
  //   localStorage.setItem('form1Data', JSON.stringify(formData));
  // };

  const handleSubmit = () => {

    const currentDateTime = new Date().toISOString();

    setConfirmAction(() => async () => {
      const formDataWithStatus = {
        ...formData,
        caseStatus: 'Completed',
        inspectionDateTime: formData.inspectionDateTime || caseData?.inspectionDateTime,
      };

      try {
        setTimeout(() => {
          jwtAxios.put(`/api/case/${caseData._id}`, formDataWithStatus)
          .then(response => {
            console.log('Update successful:', response);
            toast.success('Case Updated successfully');
            clearFormData();
          })
          .catch(error => {
            console.error('Error updating data:', error);
            toast.error('Error updating data:');
          });
          
        }, 3000);
      }
      catch (error) {
        console.log(error);
      }
      finally {
        setOpenDialog(false);
      }

    })
      setOpenDialog(true);
    
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirm = () => {
    confirmAction();
  }
  
  if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  // console.log("Car CaseData Form", caseData);

    return (
      <Container maxWidth="md">
        <Box>
          { !vehicleData?.result?.regNo && (
            <Alert severity="warning">Vehicle Number is not registered with Vahan</Alert>
          )}
          <Box
            sx={{
              mt: 2
            }}
          >
            {/* Title */}
            <Box backgroundColor='#fefefe'
              sx={{
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px'
              }}
              padding={5}>
                <Typography>Name: {vehicleData?.result?.owner || userDetails.name}</Typography>
                <Typography>Email: {userDetails.email}</Typography>
                <Typography>Number: {vehicleData?.result?.mobileNumber || userDetails.number}</Typography>
                <Typography>Vehicle Name: {vehicleData?.result?.mappings?.variantIds[0]?.make || userDetails.vehicleName}</Typography>
                <Typography>Color: {vehicleData?.result?.vehicleColour || userDetails.color}</Typography>
                <Typography>License Plate: {vehicleData?.result?.regNo || userDetails.licensePlate}</Typography>
                <Typography>Address: {vehicleData?.result?.presentAddress || userDetails.address}</Typography>
              <Typography variant="h5" fontSize='24px' fontWeight='600' sx={{ mb: 3 }}>
                Car Detail {caseData?.subCaseType && <span style={{ fontSize: '14px', color: 'gray' }}>({caseData?.subCaseType})</span>}
              </Typography>
  
              {/* Form Fields */}
              <TextField
                label="Case Id"
                name="caseId"
                value={formData.caseId}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
              <TextField
                label="Make"
                name="make"
                value={formData.make}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Model"
                name="model"
                value={formData.model}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Variant"
                name="variant"
                value={formData.variant}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <Box display="flex" gap={3} justifyContent="space-between" mb={2}>
  
                <TextField
                  label="Color"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  fullWidth
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
  
                <TextField
                  label="Year"
                  name="year"
                  value={formData.year}
                  onChange={handleChange}
                  fullWidth
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
              </Box>
  
              <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '500' }}>
                  Vehicle Running Condition
                </Typography>
                <RadioGroup
                  name="vehicleRunningCondition"
                  value={formData.vehicleRunningCondition}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
  
              <TextField
                label="Chassis"
                name="chasis"
                value={formData.chasis}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Fuel Used"
                variant="outlined"
                name="fuelUsed"
                value={formData.fuelUsed}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Engine no"
                // variant="outlined"
                name="engineNumber"
                value={formData.engineNumber}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Insurance Upto"
                // variant="outlined"
                name="insuranceUpto"
                value={formData.insuranceUpto}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Insurance Company"
                // variant="outlined"
                name="insuranceCompany"
                value={formData.insuranceCompany}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                fullWidth
                label="Odometer"
                variant="outlined"
                name="odometerType"
                value={formData.odometerType}
                onChange={handleChange}
                sx={{
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Insurer/Broker"
                name="insurer"
                value={formData.insurer}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Inspector Name"
                name="inspectorName"
                value={formData.inspectorName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Purpose"
                name="purpose"
                value={formData.purpose}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Proposer"
                name="proposer"
                value={formData.proposer}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Requestor"
                name="requestor"
                value={formData.requestor}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="QC Name"
                name="qcName"
                value={formData.qcName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Executive Name"
                name="executiveName"
                value={formData.executiveName}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <TextField
                label="Remarks"
                name="remarks"
                value={formData.remarks}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Left
              </Typography>
  
              <SelectField
                label="LT Front Fender"
                name="leftFrontFender"
                value={formData.leftFrontFender}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="LT Rear Fender"
                name="leftRearFender"
                value={formData.leftRearFender}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Tyre LT"
                name="leftFrontTyre"
                value={formData.leftFrontTyre}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Rim Left"
                name="frontRimLeft"
                value={formData.frontRimLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Front Door"
                name="leftFrontDoor"
                value={formData.leftFrontDoor}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Front Door Glass"
                name="leftFrontDoorGlass"
                value={formData.leftFrontDoorGlass}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Outside Rear View Mirror LT"
                name="leftOutsideRearViewMirror"
                value={formData.leftOutsideRearViewMirror}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Pillar Front (A)"
                name="leftPillarFront"
                value={formData.leftPillarFront}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Pillar Center (B)"
                name="leftPillarCenter"
                value={formData.leftPillarCenter}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Pillar Front (C)"
                name="leftPillarRear"
                value={formData.leftPillarRear}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Rear Door"
                name="leftRearDoor"
                value={formData.leftRearDoor}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Rear Door Glass"
                name="leftRearDoorGlass"
                value={formData.leftRearDoorGlass}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Running Board"
                name="leftRunningBoard"
                value={formData.leftRunningBoard}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="LT Qtr Panel"
                name="leftQRTPanel"
                value={formData.leftQRTPanel}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Tyre LT"
                name="leftRearTyre"
                value={formData.leftRearTyre}
                onChange={handleChange}
                options={option}
              /> 
  
              <SelectField
                label="Rear Rim Left"
                name="rearRimLeft"
                value={formData.rearRimLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Left Trafficator"
                name="leftTrafficator"
                value={formData.leftTrafficator}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Right
              </Typography>
  
              <SelectField
                label="RT Front Fender"
                name="rightFrontFender"
                value={formData.rightFrontFender}
                onChange={handleChange}
                options={option}
              />

              <SelectField
                label="RT Rear Fender"
                name="rightRearFender"
                value={formData.rightRearFender}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Tyre RT"
                name="rightFrontTyre"
                value={formData.rightFrontTyre}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Rim Right"
                name="frontRimRight"
                value={formData.frontRimRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Front Door "
                name="rightFrontDoor"
                value={formData.rightFrontDoor}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Front Door Glass"
                name="rightFrontDoorGlass"
                value={formData.rightFrontDoorGlass}
                onChange={handleChange}
                options={option}
              />
              <SelectField
                label="Outside Rear View Mirror RT"
                name="rightOutsideRearViewMirror"
                value={formData.rightOutsideRearViewMirror}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Inside Rear view mirror"
                name="insideRearViewMirror"
                value={formData.insideRearViewMirror}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Pillar Front (A)"
                name="rightPillarFront"
                value={formData.rightPillarFront}
                onChange={handleChange}
                options={option}
              />
  
               <SelectField
                label="RT Pillar Center (B)"
                name="rightPillarCenter"
                value={formData.rightPillarCenter}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Pillar Rear (C)"
                name="rightPillarRear"
                value={formData.rightPillarRear}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Rear Door"
                name="rightRearDoor"
                value={formData.rightRearDoor}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Rear Door Glass"
                name="rightRearDoorGlass"
                value={formData.rightRearDoorGlass}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT Running Board"
                name="rightRunningBoard"
                value={formData.rightRunningBoard}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="RT QRT Panel"
                name="rightQRTPanel"
                value={formData.rightQRTPanel}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Tyre RT"
                name="rightRearTyre"
                value={formData.rightRearTyre}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Rim Right"
                name="rearRimRight"
                value={formData.rearRimRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Right Trafficator"
                name="rightTrafficator"
                value={formData.rightTrafficator}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Indicator LT"
                name="leftRearIndicator"
                value={formData.leftRearIndicator}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Indicator RT"
                name="rightRearIndicator"
                value={formData.rightRearIndicator}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Front
              </Typography>
  
              <SelectField
                label="Front Bumber"
                name="frontBumper"
                value={formData.frontBumper}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Grill"
                name="grill"
                value={formData.grill}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Head Light LT"
                name="headLightLeft"
                value={formData.headLightLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Head Light RT"
                name="headLightRight"
                value={formData.headLightRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Indicator LT"
                name="frontIndicatorLeft"
                value={formData.frontIndicatorLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Indicator RT"
                name="frontIndicatorRight"
                value={formData.frontIndicatorRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Fog Lamp LT"
                name="fogLampLeft"
                value={formData.fogLampLeft}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Fog Lamp RT"
                name="fogLampRight"
                value={formData.fogLampRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front Panel"
                name="frontPanel"
                value={formData.frontPanel}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Bonnet Front"
                name="bonnetFront"
                value={formData.bonnetFront}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="ApronLt"
                name="leftApron"
                value={formData.leftApron}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Apron RT"
                name="rightApron"
                value={formData.rightApron}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Front W/S Glass"
                name="frontWindowGlass"
                value={formData.frontWindowGlass}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Rear
              </Typography>
  
              <SelectField
                label="Dicky Rear"
                name="dickyRear"
                value={formData.dickyRear}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear W/S Glass"
                name="rearWindowGlass"
                value={formData.rearWindowGlass}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Rear Bumper"
                name="rearBumper"
                value={formData.rearBumper}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Tail Light LT"
                name="leftTailRight"
                value={formData.leftTailRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Tail Light RT"
                name="rightTailRight"
                value={formData.rightTailRight}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Spare Tyre Rear"
                name="spareTyreRear"
                value={formData.spareTyreRear}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Top/Bottom
              </Typography>
  
              <SelectField
                label="Roof Panel"
                name="roof"
                value={formData.roof}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Floor Top"
                name="floorTop"
                value={formData.floorTop}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Silencer/Exhaust"
                name="silencer"
                value={formData.silencer}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Under Carriage"
                name="underCarriage"
                value={formData.underCarriage}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Accessories
              </Typography>
  
              <SelectField
                label="Stereo"
                name="stereo"
                value={formData.stereo}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Center Locking System"
                name="centerLockingSystem"
                value={formData.centerLockingSystem}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Airbag Condition"
                name="airBagCondition"
                value={formData.airBagCondition}
                onChange={handleChange}
                options={option}
              />
  
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Tyre
              </Typography>
  
  
              {/* <SelectField
                label="Front Tyre Right"
                name="frontTyreRhs"
                value={formData.frontTyreRhs}
                onChange={handleChange}
                options={option}
              /> */}
  
              <TextField
                label="Front Tyre Right"
                name="frontTyreRhs"
                value={formData.frontTyreRhs}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              {/* <SelectField
                label="Front Tyre Left"
                name="frontTyreLhs"
                value={formData.frontTyreLhs}
                onChange={handleChange}
                options={option}
              /> */}
  
              <TextField
                label="Front Tyre Left"
                name="frontTyreLhs"
                value={formData.frontTyreLhs}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              {/* <SelectField
                label="Rear Tyre Right"
                name="rearTyreRhs"
                value={formData.rearTyreRhs}
                onChange={handleChange}
                options={option}
              /> */}
  
              <TextField
                label="Rear Tyre Right"
                name="rearTyreRhs"
                value={formData.rearTyreRhs}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              {/* <SelectField
                label="Rear Tyre Left"
                name="rearTyreLhs"
                value={formData.rearTyreLhs}
                onChange={handleChange}
                options={option}
              /> */}
  
              <TextField
                label="Rear Tyre Left"
                name="rearTyreLhs"
                value={formData.rearTyreLhs}
                onChange={handleChange}
                fullWidth
                sx={{ 
                  mb: 2,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  '& fieldset': {
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    borderRadius: '0px'
                  }
                }}
              />
  
              {/* /////////////////////////////////////////////////////////////// */}
   
              <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '18px', fontWeight: '500' }}>
                  Others
              </Typography>
  
              <SelectField
                label="Sunroof"
                name="sunroof"
                value={formData.sunroof}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Dickey/Trunk LID"
                name="trunkLid"
                value={formData.trunkLid}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Engine Sheild"
                name="engineShield"
                value={formData.engineShield}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Battery"
                name="battery"
                value={formData.battery}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Wheel Type"
                name="wheelType"
                value={formData.wheelType}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Spear Wheels"
                name="spearWheels"
                value={formData.spearWheels}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Wheels RIMS"
                name="wheelRims"
                value={formData.wheelRims}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Dashboard"
                name="dashboard"
                value={formData.dashboard}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Instrument Cluster"
                name="instrumentCluster"
                value={formData.instrumentCluster}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="CD Changer"
                name="cdChanger"
                value={formData.cdChanger}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Air Conditioner"
                name="airConditioner"
                value={formData.airConditioner}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Seat Cover"
                name="seatCover"
                value={formData.seatCover}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Gear Lock"
                name="gearLock"
                value={formData.gearLock}
                onChange={handleChange}
                options={option}
              />
  
              <SelectField
                label="Other (If Any)"
                name="other"
                value={formData.other}
                onChange={handleChange}
                options={option}
              />
  
              <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ marginTop: '10px', fontSize: '16px', fontWeight: '500' }}>
                  QC Status
                </Typography>
                <RadioGroup
                  name="qcStatus"
                  value={formData.qcStatus}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="Recommended" control={<Radio />} label="Recommended" />
                  <FormControlLabel value="Not Recommended" control={<Radio />} label="Not Recommended" />
                  <FormControlLabel value="Refer to Underwriter" control={<Radio />} label="Refer to Underwriter" />
                </RadioGroup>
              </FormControl>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Customer Sign</Typography>
              <CustomInput
                type="file"
                accept="image/png, image/gif, image/jpeg"
                // multiple
                onChange={(event) => handleFileChange(event, 'cusSign')}
                inputProps={{ accept: 'image/*' }}
                // fullWidth
                // margin="normal"
              />
  
              <Grid container spacing={2} mt={3}>
                {formData.cusSign.length > 0 ? (
                  <Grid item xs={12} sm={6} md={4}>
                    <img src={formData.cusSign} alt="logo"/>
                    <Button variant="outlined" onClick={() => handleFileRemoveCusSign('cusSign')}>
                      Remove
                    </Button>
                  </Grid>
                ) : null
                  
                }
              </Grid>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Upload Images</Typography>
              <CustomInput
                type="file"
                accept="image/png, image/gif, image/jpeg"
                multiple
                onChange={(event) => handleFileChange(event, 'images')}
                inputProps={{ accept: 'image/*' }}
                // fullWidth
                // margin="normal"
              />
              <Grid container spacing={2} mt={3}>
                {formData.images.map((image, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    {/* <Typography variant="body2">{image}</Typography> */}
                    <img src={image} alt="logo"/>
                    <Grid display='flex' justifyContent='space-between'>
                      <Button variant="outlined" onClick={() => handleFileRemoveImages(index, 'images')}>
                        Remove
                      </Button>
  
                      <Button variant="outlined" onClick={() => handleFileDownload(image)}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
  
              <Typography variant="subtitle1" marginTop={3} marginBottom={3} gutterBottom sx={{fontSize: '18px', fontWeight: '500' }}>Upload Videos</Typography>
              <Input
                type="file"
                accept='video/mp4, video/mpeg'
                multiple
                onChange={(event) => handleFileChange(event, 'video')}
                inputProps={{ accept: 'video/*' }}
                fullWidth
                margin="normal"
              />
  
              <Grid container spacing={2} m={3}>
                {formData.video.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* <Typography variant="body2">{formData.video}</Typography> */}
                      <video controls style={{ width: '100%' }}>
                        <source src={formData.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Grid>
  
                    <Grid display='flex' justifyContent='space-between' gap={5}>
                      <Button variant="outlined" onClick={() => handleFileRemoveVideo('video')}>
                        Remove
                      </Button>
  
                      <Button variant="outlined" onClick={() => handleFileDownload(formData.video)}>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                ) : <Typography variant="body2">Wait Till Upload ....</Typography>}
              </Grid>
  
              <Grid container spacing={2} m={3}>
                <TextField
                  label="Inspection Date & Time"
                  name="inspectionDateTime"
                  value={formData.inspectionDateTime}
                  onChange={handleChange}
                  type='datetime-local'
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ 
                    mb: 2,
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    '& fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderRadius: '0px'
                    }
                  }}
                />
              </Grid>
  
            </Box>
          </Box>
        </Box>
  
  
  
        {/* Footer */}
        <Box sx={{
          alignItems: 'center',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        >
          <Box display="flex" padding={2} backgroundColor='#fefefe' justifyContent="flex-end" alignItems="center"
            sx={{
              borderBottomRightRadius: '20px',
              borderBottomLeftRadius: '20px'
            }}
          >
            <Button
              sx={{
                backgroundColor: '#E5D3FC',
                color: '#6B08EC',
                fontWeight: '600',
                fontSize: '16px',
                borderRadius: '30px',
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
  
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
              {"Confirm Submission"}
          </DialogTitle>
          <DialogContent>
              <p>Are you sure you want to confirm?</p>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                  Confirm
              </Button>
          </DialogActions>
        </Dialog>
  
        <ToastContainer /> {/* To show pop up message */}
      </Container>
    );

};

export default Car;
