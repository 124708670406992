import React, { useState } from 'react';
import {
    Box,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SmartInspectLogo from '../../../../../@crema/components/PdfDownload/images/SmartInspectLogo.jpeg'

const DynamicForm = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedOption === 'smartInspect') {
            navigate('/smart-inspect');
        } else if (selectedOption === 'instaValuer') {
            navigate('/insta-valuer');
        }
    };

    return (
        <Box sx={{
            width: { xs: '100%', sm: '70%', md: '50%' },
            margin: '0 auto',
            padding: 5,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: 2,
            // height: '100vh'
        }}
        >
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
                <img
                    src={SmartInspectLogo}
                    alt="Smart Inspect Logo"
                    style={{
                        width: '100%',
                        maxWidth: '400px',
                        height: 'auto' 
                    }}
                />
            </Box>
            <Typography variant="h4">Choose an Option</Typography>
            <FormControl component="fieldset">
                <RadioGroup row value={selectedOption} onChange={handleOptionChange}>
                    <FormControlLabel value="smartInspect" control={<Radio />} label="SmartInspect" />
                    <FormControlLabel value="instaValuer" control={<Radio />} label="InstaValuer" />
                </RadioGroup>
            </FormControl>

            <Box marginTop={2}>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default DynamicForm;
