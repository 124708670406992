import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, TextField, Button, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, CardContent, Card, Box, Typography } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL, API_KEY, getCookie, sessionExpired } from '../../../constants/utilis';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import jwtAxios from '@crema/services/auth/jwt-auth';
import { toast, ToastContainer } from 'react-toastify';

const Client = () => {
  const [client, setClient] = useState('');
  const [menu, setMenu] = useState('');
  const [firebaseData, setFirebaseData] = useState([]);
  const [dataId, setDataId] = useState('');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = async () => {
    try {
      const response = await jwtAxios.get('auth/bank')
      const sortedData = response.data.sort((a,b) => a.name.localeCompare(b.name));
      setFirebaseData(sortedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  // const handleNon200Response = (error, functionIndex) => {
  //   if (error.response.status === 401 && error.response.data.message === 'User Not authenticated') {
  //     sessionExpired();
  //   }
  //   // Handle 403 status if needed
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (client === '') {
      // alert('Enter Client Name');
      toast.error('Enter  Client Name');
      return;
    }

    if (menu === '') {
      // alert('Select Status');
      toast.error('Select Status');
      return;
    }

    const data = {
      name: client,
      status: menu,
      pushid: Date.now()
    }

    try {
      await jwtAxios.post('auth/bank', data)
        setClient('');
        setMenu('');
        fetchList();
        // alert('Added Successfully');
        toast.success('Added Successfully');
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (client === '') {
      // alert('Enter Client Name');
      toast.error('Enter Client Name');
      return;
    }

    if (menu === '') {
      // alert('Select Status');
      toast.error('Select Status');
      return;
    }

    const data = {
      name: client,
      status: menu,
      pushid: firebaseData.pushid
    }

    try {
      await jwtAxios.put(`auth/bank/${dataId}`, data);
      fetchList();
        setClient('');
        setMenu('');
        fetchList();
        setEdit(false);
        // alert('Updated Successfully');
        toast.success('Updated Successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (rowData) => {
    setEdit(true);
    setClient(rowData.name);
    setMenu(rowData.status);
    setDataId(rowData._id);
  };

  const handleDelete = async (rowData) => {
    if (window.confirm('Are you sure want to delete?')) {
      try {
        const response = await jwtAxios.delete(`auth/bank/${rowData._id}`);
          fetchList();
          setEdit(false);
          // alert('Deleted Successfully');
          toast.success('Deleted Successfully');
      } catch (error) {
        console.log(error);
      }
    }
  };


  return (
    <div className="page-content">
      <Container>
      <Typography variant="h2" fontWeight={800} marginBottom={4}>Client</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Typography variant='subtitle'>Client Name</Typography>
                      <TextField
                        fullWidth
                        label="Client Name"
                        value={client}
                        onChange={(e) => setClient(e.target.value)}
                        required
                        sx={{ marginTop: '10px'}}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Typography variant='subtitle'>Status</Typography>
                      <TextField
                        fullWidth
                        select
                        label="Status"
                        value={menu}
                        onChange={(e) => setMenu(e.target.value)}
                        required
                        sx={{ marginTop: '10px'}}
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="InActive">InActive</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between">
                      {!edit ? <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                      </Button> : (
                        <Button variant="contained" color="secondary" onClick={handleUpdate}>
                          Update
                        </Button>
                      )}
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.No</TableCell>
                      <TableCell>Client</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {firebaseData.map((rowData, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{rowData.name}</TableCell>
                        <TableCell>{rowData.status}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEdit(rowData)}>
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton color='secondary' onClick={() => handleDelete(rowData)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <ToastContainer /> {/* To show a toast message */}
    </div>
  );
};

export default Client;
