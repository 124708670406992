import { useEffect, useState } from "react";
import CarPart from "./CarPart";

const CarDiagram = ({ data }) => {

  const initialParts = [
    // Front Bumper
    {
      id: 1,
      name: "Bumper",
      d: "M27.5 33.5H24.5L25.5 25L4 24L0.5 1H112L107 25H86L87.5 33.5H84.5L80 22H33L27.5 33.5Z",
      transform: "translate(135, 30)", // Adjust position
      value: (data?.partsResponseAi?.frontBumper || data?.frontBumper), // `"${data?.frontBumper}"`
    },
    // Front Grill
    {
      id: 2,
      name: "Bumper-2 (grill)",
      d: "M22.5 9.5L19 13H1.5L0.5 10.5L4.5 1H44.5L49.5 10.5L47.5 13H30L27 9.5H22.5Z",
      transform: "translate(167, 58)", // Adjust position
      value: (data?.partsResponseAi?.grill || data?.grill), // `"${data?.grill}"`
    },
    {
      id: 3,
      name: "Bumper-2 dot",
      d: "M 2.5 1 A 1.5 1.5 0 1 1 2.499 4 A 1.5 1.5 0 1 1 2.5 1",
      transform: "translate(189, 70)", // Adjust position
      value: "Intact",
    },
    // Front Bonnet
    {
      id: 4,
      name: "Front Bonnet",
      d: "M42.5 0C20.5 0 6.66667 10.3333 2.5 15.5C1.83333 27.8333 0.5 52.9 0.5 54.5C0.5 56.5 2.5 56.5 5 56.5C7.5 56.5 29 48.5 41.5 48.5C54 48.5 77 56 80 57.5C82.4 58.7 84 56 84.5 54.5L81.5 15.5C77.6667 10.3333 64.5 0 42.5 0Z",
      transform: "translate(150, 80)", // Adjust position
      value: (data?.partsResponseAi?.bonnetFront || data?.bonnetFront),
    },
    // Left Side Head Light
    {
      id: 5,
      name: "Front Left Head Light",
      d: "M22.5 10L24.5 1H9.5L1 4L3 13.5L22.5 10Z",
      transform: "translate(130, 60)", // Adjust position
      value: (data?.partsResponseAi?.headLightLeft || data?.headLightLeft),
    },

    // Left front fender
    {
      id: 6,
      name: "Left Bonnet Tyre (fender)",
      d: "M24 39.5L26.5 0.5L1 4.5V13.5C4.66667 14 12.1 17.2 12.5 26C12.9 34.8 6 39.3333 2.5 40.5V47L24 39.5Z",
      transform: "translate(120, 95)", // Adjust position
      value: (data?.partsResponseAi?.leftFrontFender || data?.leftFrontFender),
    },
    // Tyres are same for all
    {
      id: 7,
      name: "Front Left Tyre",
      d: "M 11 0.375 A 10.625 10.625 0 1 1 10.999 21.375 A 10.625 10.625 0 1 1 11 0.375",
      transform: "translate(104, 112)", // Adjust position
      value: (data?.partsResponseAi?.leftFrontTyre || data?.leftFrontTyre),
    },
    // Left Outside Mirror
    {
      id: 8,
      name: "Front Left Mirror",
      d: "M19.5 1C19.4493 1 19.3958 1.00046 19.3395 1.0014M19.3395 1.0014C17.7297 1.0284 13.8648 1.45407 10 3C6 4.6 2.66667 6.66667 1.5 7.5C1.33333 9.16667 1.1 12.6 1.5 13C1.9 13.4 13.5061 9.6728 19.3395 7.83947V1.0014Z",
      transform: "translate(128, 142)", // Adjust position
      value: (data?.partsResponseAi?.leftOutsideRearViewMirror || data?.leftOutsideRearViewMirror),
    },
    // Left Front Door
    {
      id: 9,
      name: "Left Door",
      d: "M0.5 43.5V0.5H19C25 3 29.5 10 33.5 17.5C36.7 23.5 38.5 37.3333 39 43.5H0.5Z",
      transform: "translate(120, 165)", // Adjust position
      value: (data?.partsResponseAi?.leftFrontDoor || data?.leftFrontDoor),
    },
    // Left Rear Door
    {
      id: 10,
      name: "Left Door Back (rear)",
      d: "M0.500195 25V0.5H39.0002C39.3335 2.5 39.6932 10.4593 34.5002 25C29.5003 39 17.8336 43.5 12.5002 43.5C12.5002 41.1667 13.0002 36.5 10.0002 31C7.27292 26 2.66686 25.1667 0.500195 25Z",
      transform: "translate(120, 215)", // Adjust position
      value: (data?.partsResponseAi?.leftRearDoor || data?.leftRearDoor),
    },

    // Back Left fender
    {
      id: 11,
      name: "Back Left Tyre Body",
      d: "M7.5 5.49997C9.32127 4.04293 11 1.66664 11.5 0.499973C16.3 0.0999729 18.8333 2.33331 19.5 3.49997V32H0.5V7.99997C2 7.66664 5 7.5 7.5 5.49997Z",
      transform: "translate(120, 263)", // Adjust position
      value: (data?.leftRearFender),
    },
    // Tyres are same for all
    {
      id: 12,
      name: "Back Left Tyre",
      d: "M 11 0.375 A 10.625 10.625 0 1 1 10.999 21.375 A 10.625 10.625 0 1 1 11 0.375",
      transform: "translate(105, 245)", // Adjust position
      value: (data?.partsResponseAi?.leftRearTyre || data?.leftRearTyre),
    },
    // Left Running Board
    {
      id: 13,
      name: "Foot Left Step (running board)",
      d: "M2 0.5L1 1.5V96L2 97H11L11.5 96V1.5L10.5 0.5H2Z",
      transform: "translate(103, 142)", // Adjust position
      value: (data?.partsResponseAi?.leftRunningBoard || data?.leftRunningBoard),
    },
    // Right Side Head Light
    {
      id: 14,
      name: "Front Right Head Light",
      d: "M2.5 10L0.5 1H15.5L24 4L22 13.5L2.5 10Z",
      transform: "translate(227, 60)", // Adjust position
      value: (data?.partsResponseAi?.headLightRight || data?.headLightRight),
    },

    // Right Front fender
    {
      id: 15,
      name: "Right Bonnet Tyre (fender)",
      d: "M3 39.5L0.5 0.5L26 4.5V13.5C22.3333 14 14.9 17.2 14.5 26C14.1 34.8 21 39.3333 24.5 40.5V47L3 39.5Z",
      transform: "translate(238, 95)", // Adjust position
      value: (data?.partsResponseAi?.rightFrontFender || data?.rightFrontFender),
    },
    // Tyres are same for all
    {
      id: 16,
      name: "Front Right Tyre",
      d: "M 11 0.375 A 10.625 10.625 0 1 1 10.999 21.375 A 10.625 10.625 0 1 1 11 0.375",
      transform: "translate(258, 112)", // Adjust position
      value: (data?.partsResponseAi?.rightFrontTyre || data?.rightFrontTyre),
    },
    // Right Outside Mirror
    {
      id: 17,
      name: "Front Right Mirror",
      d: "M0.5 1C0.550687 1 0.604229 1.00046 0.660529 1.0014M0.660529 1.0014C2.27033 1.0284 6.13517 1.45407 10 3C14 4.6 17.3333 6.66667 18.5 7.5C18.6667 9.16667 18.9 12.6 18.5 13C18.1 13.4 6.49386 9.6728 0.660529 7.83947V1.0014Z",
      transform: "translate(238, 142)", // Adjust position
      value: (data?.partsResponseAi?.rightOutsideRearViewMirror || data?.rightOutsideRearViewMirror),
    },
    // Right Front Door
    {
      id: 18,
      name: "Right Door",
      d: "M39.5 43.5V0.5H21C15 3 10.5 10 6.5 17.5C3.3 23.5 1.5 37.3333 1 43.5H39.5Z",
      transform: "translate(225, 165)", // Adjust position
      value: (data?.partsResponseAi?.rightFrontDoor || data?.rightFrontDoor),
    },
    // Right Rear Door
    {
      id: 19,
      name: "Right Door Back",
      d: "M39.4998 25V0.5H0.999806C0.666473 2.5 0.306757 10.4593 5.49981 25C10.4997 39 22.1664 43.5 27.4998 43.5C27.4998 41.1667 26.9998 36.5 29.9998 31C32.7271 26 37.3331 25.1667 39.4998 25Z",
      transform: "translate(225, 215)", // Adjust position
      value: (data?.partsResponseAi?.rightRearDoor || data?.rightRearDoor),
    },
    // Back Right fender
    {
      id: 20,
      name: "Back Right Tyre Body",
      d: "M12.5 5.49997C10.6787 4.04293 9 1.66664 8.5 0.499973C3.7 0.0999729 1.16667 2.33331 0.5 3.49997V32H19.5V7.99997C18 7.66664 15 7.5 12.5 5.49997Z",
      transform: "translate(245, 263)", // Adjust position
      value: (data?.rightRearFender),
    },
    // Tyres are same for all
    {
      id: 21,
      name: "Rear Right Tyre",
      d: "M 11 0.375 A 10.625 10.625 0 1 1 10.999 21.375 A 10.625 10.625 0 1 1 11 0.375",
      transform: "translate(258, 245)", // Adjust position
      value: (data?.partsResponseAi?.rightRearTyre || data?.rightRearTyre),
    },
    // Right Running Board
    {
      id: 22,
      name: "Foot Right Step",
      d: "M2 0.5L1 1.5V96L2 97H11L11.5 96V1.5L10.5 0.5H2Z",
      transform: "translate(270, 142)", // Adjust position
      value: (data?.partsResponseAi?.rightRunningBoard || data?.rightRunningBoard),
    },
    // Roof
    {
      id: 23,
      name: "Roof",
      d: "M28.0002 1C37.6002 1 50.0002 3.66667 55.0002 5C55.7681 5.20478 55.3797 7.36158 55.0002 8.5C54.0002 11.5 53.5002 23 53.5002 29C53.5002 33.5 54.0002 49.5 54.0002 52C54.0002 54.5 53.5002 54.5 52.0002 54.5C50.5002 54.5 35.0002 56.5 28.0002 56.5C23.5002 56.5 9.00024 55.5 6.00024 54.5C3.00024 53.5 2.50021 54 2.50021 52C2.50021 50 4.00024 40 4.00024 31C4.00024 22 2.00021 8 1.50021 7C1.00021 6 1.00021 4 2.50021 3.5C4.00021 3 16.0002 1 28.0002 1Z",
      transform: "translate(165, 180)", // Adjust position
      value: (data?.roof),
    },
    // Front WindowGlass
    {
      id: 24,
      name: "Front WindowGlass",
      d: "M41.5 0.499815C59.1 0.899815 75 8.99982 80 12.4998C80.5 13.4998 80.5 14.9998 80 16.4998C79.6205 17.6382 73.6667 33.9998 70.5 41.9998C70 42.4998 68.6 43.3998 67 42.9998C65 42.4998 52.5 39.4998 40.5 39.4998C30.9869 39.4998 17.5 40.9998 15 41.9998C12.5 42.9998 12 42.9998 10.5 41.9998C9 40.9998 0.5 15.9998 0.5 13.9998C0.5 12.3998 1.5 11.3331 2 10.9998C7.83333 7.33315 23.9 0.0998147 41.5 0.499815Z",
      transform: "translate(153, 135)",
      value: (data?.partsResponseAi?.frontWindowGlass || data?.frontWindowGlass),
    },
    // Back  WindowGlass
    {
      id: 25,
      name: "Back WindowGlass",
      d: "M61.0002 1C61.8002 1 62.3335 1.66667 62.5002 2C64.3336 5.83333 68.1002 14 68.5002 16C69.0002 18.5 68.5002 21.5 65.5002 24C62.5002 26.5 49.5002 30 34.5002 31C19.5002 32 3.50021 24 2.00021 22.5C0.50021 21 0.50021 17 1.00021 15.5C1.50021 14 5.50021 4 6.00021 2.5C6.50021 1 7.00021 1 8.50021 1C10.0002 1 26.0002 4 34.5002 4C43.0002 4 60.0002 1 61.0002 1Z",
      transform: "translate(158, 240)",
      value: (data?.partsResponseAi?.rearWindowGlass || data?.rearWindowGlass),
    },
    // Rear Dicky
    {
      id: 26,
      name: "Dicky",
      d: "M6 0.500071C6.4 0.100071 6.83333 1.00007 7 1.50007C6.66667 3.3334 6.45917 7.77895 7 8.50006C8.5 10.5001 18 20.0001 43.5 20.0001C69 20.0001 79 9.00006 79.5 8.50006C80 8.00006 80 4.00006 80 2.50006C80 1.00006 81 0.500071 82 2.00006C82.8 3.20005 85 8.16672 86 10.5001C86.1667 14.0001 86 21.9001 84 25.5001C81.5 30.0001 67 41.5001 43.5 41.5001C19.4792 41.5001 3 25.0001 2.5 24.0001C2 23.0001 1 21.0001 1 17.0001C1 13.0001 1 9.50006 2 7.00006C3 4.50006 5.5 1.00007 6 0.500071Z",
      transform: "translate(149, 257)",
      value: (data?.partsResponseAi?.dickyRear || data?.dickyRear),
    },
    // Rear Bumper
    {
      id: 27,
      name: "Back Bumper",
      d: "M124.5 24.5L126.5 10.5L91 1H36L1 10.5L3 25L10.5 25.5L12 24L114.5 24.5L116.5 26L124.5 24.5Z",
      transform: "translate(130, 318)",
      value: (data?.partsResponseAi?.rearBumper || data?.rearBumper),
    },
    // Tail Lamp RT
    {
      id: 28,
      name: "Back Right Light",
      d: "M13 12H25.5L24 3.5L23.5 2L17.5 1H8.5L1 2.5L1.5 8.5L2.5 10.5L13 11V12Z",
      transform: "translate(230, 305)",
      value: (data?.partsResponseAi?.rightTailRight || data?.rightTailRight),
    },
    // Tail Lamp  LT
    {
      id: 29,
      name: "Back Left Light",
      d: "M13.5 12H1L2.5 3.5L3 2L9 1H18L25.5 2.5L25 8.5L24 10.5L13.5 11V12Z",
      transform: "translate(130, 305)",
      value: (data?.partsResponseAi?.leftTailRight || data?.leftTailRight),
    },
    // Back Grill not in database
    {
      id: 30,
      name: "Back Bumper",
      d: "M2.5 1H4L13 7H18V4.5L19 3.5H23L24 4.5V6.5H111V4.5L112 3.5H115L116.5 4.5V6.5H122L130 1L131.5 0.5L134 2.5V4.5L123.5 11.5H116.5V13.5L115 14.5H112L111 14L110.5 12.5H23V13.5L22.5 14.5H19L18 13.5V11.5H10.5L0.5 4.5V3L2.5 1Z",
      transform: "translate(126, 348)",
      value: "Intact",
    },
    // Add paths for all parts
  ];

  const getColor = (value) => {
    if (value === "Intact") return "none";
    if (value === "Scratched") return "orange";
    return "red"; // For "Damaged" or any other case
  };

  const [parts, setParts] = useState([]);

  useEffect(() => {
    if (data) {
      const updatedParts = initialParts.map((part) => ({
        ...part,
        color: getColor(part.value),
      }));
      setParts(updatedParts);
    }
  }, [data]);

  if (!data) {
    return <div>Loading...</div>;
  }
  
  return (
    <svg
      id="carDiagram"
      width="400"
      height="600"
      viewBox="0 0 400 600"
      xmlns="http://www.w3.org/2000/svg"
    >
      {parts.map((part) => (
        <CarPart
          key={part.id}
          partId={part.id}
          d={part.d}
          color={part.color}
          // handleClick={handleClick}
          transform={part.transform}
        />
      ))}
    </svg>
  );
};

export default CarDiagram;
