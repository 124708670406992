import React, { useEffect, useState } from 'react';
import AppsContainer from '@crema/components/AppsContainer';
import { Button, Hidden, TableRow, TextField, Select, MenuItem, Table, TableHead, TableBody, TableCell, Box } from '@mui/material';
import AppsHeader from '@crema/components/AppsContainer/AppsHeader';
import AppsContent from '@crema/components/AppsContainer/AppsContent';
import AppsPagination from '@crema/components/AppsPagination';
import AppInfoView from '@crema/components/AppInfoView';
import AppSearchBar from '@crema/components/AppSearchBar';
import { useAppDispatch } from '../../../toolkit/hooks';
import jwtAxios from "../../../@crema/services/auth/jwt-auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { StyledTableCell } from "../../extraPages/Pricing/PackageTable/Table/index.styled";
import Autocomplete from '@mui/material/Autocomplete';
import {openSnackbar} from "../../../toolkit/actions/snackbar";
import dayjs from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UnAssignedCases = () => {
    const dispatch = useAppDispatch();
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState({});
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const navigate = useNavigate()

    const onPageChange = (event, value) => {
        setPage(value);
    };

    const onRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSearchCustomer = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const updateTable = async () => {
        const url = `api/report/unAssignedCase`;
        try {
            const response = await jwtAxios.post(url, {
                pagination: true,
                // pageSize: rowsPerPage,
                pageCount: page + 1,
                // state: []
            });
            // const sortedCases = response.data.caseList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setTableData(response.data.caseList);
            setTotalOrders(response.data.totalRecords);
            // console.log("Data loaded:", response.data);
        } catch (error) {
            console.error("Error loading cases:", error);
        }
    };

    useEffect(() => {
        updateTable();
        fetchData();
    }, [page]);

    const fetchData = () => {
        let config = {
            method: 'get',
            url: 'https://smartinspectai.justthinktech.in/smartinspectionai/v1/api/agents',
            headers: {
                'apikey': 'c747b51cc1b18e6e9f4fdd72d6ada6c8',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        axios.request(config)
            .then((response) => {
                // console.log('agents', response);
                setAgents(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleAgentChange = (caseId, agent) => {
        setSelectedAgent(prevState => ({
            ...prevState,
            [caseId]: agent
        }));
    };

    const approveAgent = (caseData) => {
        const agent = selectedAgent[caseData._id];

        console.log("pubg monbile????")
        if (!agent) {
            dispatch(openSnackbar('Please Select Agent', 'error'));
            toast.error('Please Select Agent');
            return;
        }

        const url = `/api/case/${caseData._id}`;
        jwtAxios.put(url, { agentid: agent.agentId, agentname: agent.name })
            .then((response) => {
                console.log("Agent approved", response);
                dispatch(openSnackbar('Agent assigned successfully', 'success'));
                toast.success('Agent assigned successfully');
                updateTable();  // Refresh table after update
            })
            .catch((error) => {
                console.log("Error approving agent", error);
                dispatch(openSnackbar('Error Assigning agent', 'error'));
                toast.error('Error Assigning agent');
            });
    };

    const handleCompleteManually = (data) => {
        // console.log("UnAssigned", data);
        navigate(`/smartinspectai/manualForm/${data._id}`, { state: 
            { 
                caseData: data,
                userDetails: {
                    name: data.name,
                    email: data.email,
                    number: data.number,
                    vehicleName: data.vehicleName,
                    color: data.color,
                    licensePlate: data.licensePlate,
                    address: data.address,
                    make: data.make,
                    model: data.model,
                    variant: data.variant,
                    year: data.year,
                    chasis: data.chasis,
                    fuelUsed: data.fuelUsed,
                    engineNumber: data.engineNumber,
                    odometerType: data.odometerType,
                    images: data.images,
                    video: data.video,
                    cusSign: data.cusSign,
                    executiveName: data.executiveName,
                    insurer: data.insurer,
                    inspectorName: data.inspectorName,
                    purpose: data.purpose,
                    proposer: data.proposer,
                    requestor: data.requestor,
                    qcName: data.qcName,
                    remarks: data.remarks,
                    frontTyreRhs: data.frontTyreRhs,
                    frontTyreLhs: data.frontTyreLhs,
                    rearTyreRhs: data.rearTyreRhs,
                    rearTyreLhs: data.rearTyreLhs,
                    insuranceCompany: data.insuranceCompany,
                    insuranceUpto: data.insuranceUpto,
                }
            } 
        })
    };

    const filteredCases = tableData.filter(caseItem =>{
        // caseItem.name.toLowerCase().includes(search.toLowerCase())
        const searchTerm = search.toLowerCase();
        return (
            caseItem.name.toLowerCase().includes(searchTerm) ||
            caseItem.licensePlate.toLowerCase().includes(searchTerm) ||
            caseItem.caseId.toLowerCase().includes(searchTerm)
        );
    });
    
    
    const paginatedCases = filteredCases.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    
    // console.log("paginated", paginatedCases)
    
    return (
        <>
            <AppsContainer title={'UnAssigned Cases'} fullView>
                <AppsHeader>
                    <Box display='flex' flexDirection='row' alignItems='center' width={1} justifyContent='space-between'>
                        <AppSearchBar iconPosition='right' overlap={false} onChange={onSearchCustomer} placeholder={"Search Here"} />
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Select
                                labelId="demo-select-small-label"
                                value={rowsPerPage}
                                onChange={onRowsPerPageChange}
                                variant="outlined"
                                size='small'
                                sx={{ marginRight: 2 }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                            <Hidden smDown>
                                {/* <AppsPagination rowsPerPage={rowsPerPage} count={filteredCases.length} page={page} onPageChange={onPageChange} /> */}
                                <AppsPagination rowsPerPage={rowsPerPage} count={totalOrders} page={page} onPageChange={onPageChange} />
                            </Hidden>
                        </Box>
                    </Box>
                </AppsHeader>

                <AppsContent sx={{ paddingTop: 2.5, paddingBottom: 2.5 }}>
                    <Table stickyHeader className='table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Case Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Number</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Vehicle Name</TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>License Plate</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Assign Agent</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Manually</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedCases.map((data) => (
                                // data.approvalStatus === "Pending" || data.approvalStatus !== "Approved" ? (
                                    <TableRow key={data._id}>
                                        <StyledTableCell align='left'>{data.caseId}</StyledTableCell>
                                        <StyledTableCell align='left'>{data.name}</StyledTableCell>
                                        <StyledTableCell align='left'>{data.number}</StyledTableCell>
                                        <StyledTableCell align='left'>{data.email}</StyledTableCell>
                                        <StyledTableCell align='left'>
                                            {dayjs(data.createdAt).format('DD/MM/YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>{data.vehicleName}</StyledTableCell>
                                        <StyledTableCell align='left'>{data.color}</StyledTableCell>
                                        <StyledTableCell align='left'>{data.licensePlate}</StyledTableCell>
                                        <StyledTableCell align='left'>{data.address}</StyledTableCell>
                                        <TableCell>
                                            <Autocomplete sx={{ width: '150px' }}
                                                options={agents.filter((agent) => agent.agentId)}
                                                getOptionLabel={(option) => `${option.agentId} - ${option.name}`}
                                                onChange={(event, newValue) => handleAgentChange(data._id, newValue)}
                                                renderInput={(params) => <TextField {...params} label="Select Agent" variant="outlined" width='100%' />}
                                                value={selectedAgent[data._id] || null}
                                            />

                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{ display: 'block', minWidth: 100 }}
                                                color='success'
                                                variant='contained'
                                                type='submit'
                                                onClick={() => approveAgent(data)}
                                            >
                                                Assign Agent
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{ display: 'block', minWidth: 100 }}
                                                color='primary'
                                                variant='contained'
                                                type='button'
                                                onClick={() => handleCompleteManually(data)}
                                            >
                                                Complete Manually
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                // ) : null
                            ))}
                        </TableBody>
                    </Table>
                </AppsContent>

                <Hidden smUp>
                    <AppsPagination rowsPerPage={rowsPerPage} count={filteredCases.length} page={page} onPageChange={onPageChange} />
                    {/* <AppsPagination rowsPerPage={rowsPerPage} count={totalOrders} page={page} onPageChange={onPageChange} /> */}
                </Hidden>
            </AppsContainer>
            <ToastContainer /> {/* To show pop up message */}
            <AppInfoView />
        </>
    );
};

export default UnAssignedCases;
